import React from "react";

import { Menu } from "../../../Components/Menu";
import { Rodape } from "../../../Components/Rodape";

export const Servicos = () => {
    return (
        <>
            <div className="bg-slate-600 bg-cover">
                <div className="mb-20">
                    <Menu />
                    <br />
                </div>

                <div className="bg-slate-300 mt-5 mb-5 mx-4 md:mx-10 p-5 rounded-2xl shadow-lg">
                    <div className="text-center space-y-4 md:space-y-6">
                        <p className="text-2xl md:text-4xl font-medium text-slate-800">Serviços de Consultoria</p>
                        <p className="text-center text-base md:text-lg text-slate-700 mx-2 md:mx-10">
                            Nossa empresa está qualificada para ajustar suas atividades dentro dos padrões regulatórios vigentes.
                        </p>
                    </div>
                </div>

                {/* Começo do Card */}
                <div className="bg-slate-200 mx-4 md:mx-10 mb-10 rounded-2xl p-5 md:p-10 shadow-lg">
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
                        {/* Serviços de Saúde */}
                        <div className="p-5 rounded-2xl shadow-md hover:shadow-xl transition-shadow duration-300">
                            <div className="flex justify-center">
                                <img className="hover:scale-105 transition-transform duration-300" src="https://www.inbravisa.com.br/imagens/icone-serv.png" width="160" height="160" alt="Serviços de Saúde" />
                            </div>
                            <p className="text-xl md:text-3xl font-semibold pt-4 pb-3 text-center text-slate-800">Serviços de Saúde</p>
                            <p className="text-justify text-sm md:text-base text-slate-700">
                                Auxiliamos as empresas prestadoras de serviços de saúde de todo país na regularização de suas atividades junto à vigilância sanitária.
                            </p>
                            <ul className="list-disc list-inside text-slate-700 mt-4 ml-5 text-sm md:text-base">
                                <li>Licença Sanitária</li>
                                <li>CNES - Cadastro Nacional de Estabelecimentos de Saúde</li>
                                <li>Elaboração do Manual de Boas Práticas de Funcionamento (MBP)</li>
                                <li>Elaboração dos Procedimentos Operacionais Padronizados (POP´s)</li>
                                <li>Treinamento Online da equipe da clínica</li>
                                <li>Orientação técnica para adequação de áreas físicas obrigatórias</li>
                                <li>Programa de Gerenciamento de Resíduos de Saúde - PGRSS</li>
                            </ul>
                        </div>

                        {/* Distribuidora */}
                        <div className="p-5 rounded-2xl shadow-md hover:shadow-xl transition-shadow duration-300">
                            <div className="flex justify-center">
                                <img className="hover:scale-105 transition-transform duration-300" src="https://www.inbravisa.com.br/imagens/icone-dist.png" width="160" height="160" alt="Distribuidora" />
                            </div>
                            <p className="text-xl md:text-3xl font-semibold pt-4 pb-3 text-center text-slate-800">Importadora e Distribuidora</p>
                            <p className="text-justify text-sm md:text-base text-slate-700">
                                Estruturamos as distribuidoras e importadoras para atenderem às normas da ANVISA.
                            </p>
                            <ul className="list-disc list-inside text-slate-700 mt-4 ml-5 text-sm md:text-base">
                                <li>Obtenção de AFE/ANVISA - Autorização de Funcionamento de Empresas</li>                                
                                <li>Assessoria para Importação junto à ANVISA</li>                               
                                <li>Implantação do sistema de Boas Práticas de Funcionamento para Armazenamento e Distribuição (MBPAD)</li>
                                <li>Orientação técnica para adequação de áreas físicas obrigatórias</li>                                                                
                            </ul>
                        </div>

                        {/* Indústria */}
                        <div className="p-5 rounded-2xl shadow-md hover:shadow-xl transition-shadow duration-300">
                            <div className="flex justify-center">
                                <img className="hover:scale-105 transition-transform duration-300" src="https://www.inbravisa.com.br/imagens/icone-industria.png" width="160" height="160" alt="Indústria" />
                            </div>
                            <p className="text-xl md:text-3xl font-semibold pt-4 pb-3 text-center text-slate-800">Indústria</p>
                            <p className="text-justify text-sm md:text-base text-slate-700">
                                Oferecemos soluções regulatórias para indústrias sob vigilância sanitária, desde a abertura até a manutenção de Boas Práticas de Fabricação (BPF).
                            </p>
                            <ul className="list-disc list-inside text-slate-700 mt-4 ml-5 text-sm md:text-base">
                                <li>Obtenção de AFE/ANVISA - Autorização de Funcionamento de Empresas</li>
                                <li>Registro e Cadastro de Produtos na ANVISA</li>                                
                                <li>Implantação do sistema de Boas Práticas de Funcionamento para Fabricação (MBPF)</li>
                                <li>Orientação técnica para adequação de áreas físicas obrigatórias</li>                                                           
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="mt-10">
                    <Rodape />
                </div>
            </div>
        </>
    );
};
