import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Importando as imagens locais
import tecnologia from '../../Assets/consultoriomedico.png';
import maos from '../../Assets/apertomaos.jpg';
import cursos from '../../Assets/certificacao.jpeg';

// Ícones para os botões de navegação
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

// Componente para o botão de próximo slide
const NextArrow = ({ onClick }) => {
  return (
    <button
      className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-600 z-10"
      onClick={onClick}
    >
      <IoIosArrowForward />
    </button>
  );
};

// Componente para o botão de slide anterior
const PrevArrow = ({ onClick }) => {
  return (
    <button
      className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-600 z-10"
      onClick={onClick}
    >
      <IoIosArrowBack />
    </button>
  );
};

export const Carrossel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const images = [maos, tecnologia, cursos];
  const descriptions = [
    "Conte com a gente quando o assunto for vigilância sanitária",
    "Regularizamos empresas e produtos em todo país",
    "Cursos, treinamentos e mentorias",

  ];

  return (
    <div className="w-full h-full flex items-center justify-center">
      <Slider {...settings} className="w-full">
        {images.map((image, index) => (
          <div key={index} className="relative">
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full object-content object-center h-5/6 max-h-[700px] 
             sm:max-h-[300px] md:max-h-[400px] lg:max-h-[500px]"
            />


            {/* Texto da descrição no centro da imagem */}
            <div className="absolute inset-0 flex items-center justify-center">
              <h1 className="text-white text-2xl sm:text-lg md:text-3xl font-bold bg-black bg-opacity-50 p-4 rounded-md shadow-md">
                {descriptions[index]} {/* Exibe a descrição correspondente */}
              </h1>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
