import React from 'react';

export const PrivacyPolicyModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-3xl w-full">
                <div className="p-4 border-b">
                    <h2 className="text-xl font-bold">Política de Privacidade</h2>
                    <button onClick={onClose} className="float-right text-gray-600 hover:text-gray-800">
                        &times;
                    </button>
                    <br/>
                </div>
                <div className="p-4 max-h-96 overflow-y-auto">
                    <br />
                    <p>
                        O Inbravisa - Instituto Brasileiro de Auditoria em Vigilância Sanitária (doravante "Inbravisa") tem o compromisso de proteger a privacidade e os dados pessoais dos usuários de seu site. Esta Política de Privacidade descreve como coletamos, usamos, armazenamos e protegemos suas informações. Ao acessar e utilizar o site inbravisa.com.br, você concorda com os termos desta política.
                    </p>
                    <h3 className="mt-4 font-bold">1. Informações Coletadas</h3>
                    <p>
                        <strong>1.1. Informações Fornecidas pelo Usuário:</strong>
                        <ul className="list-disc ml-6">
                            <li>Nome completo</li>
                            <li>Endereço de e-mail</li>
                            <li>Telefone</li>
                            <li>Endereço</li>
                            <li>Informações fornecidas por meio de formulários de contato ou inscrição</li>
                        </ul>
                    </p>
                    <p>
                        <strong>1.2. Informações Coletadas Automaticamente:</strong>
                        <ul className="list-disc ml-6">
                            <li>Tipo de navegador</li>
                            <li>Páginas visitadas</li>
                            <li>Tempo de visita</li>
                            <li>URLs de referência</li>
                        </ul>
                    </p>
                    <p>
                        <br />
                        <strong> 2. Uso das Informações</strong>
                        <p>O Inbravisa utiliza as informações coletadas para as seguintes finalidades:
                            Para Fornecer e Melhorar nossos Serviços: Utilizamos suas informações para responder a suas solicitações, fornecer suporte, processar transações e melhorar a experiência do usuário.
                            Comunicações: Enviaremos comunicações relacionadas a serviços, novidades, atualizações e promoções, desde que você tenha consentido em recebê-las.
                            Análise e Melhoria do Site: Analisamos dados de uso para entender como nosso site é utilizado e implementar melhorias.
                            Conformidade Legal: Utilizaremos suas informações conforme necessário para cumprir com nossas obrigações legais e regulatórias.
                        </p>
                    </p>

                    <p><strong> 3. Compartilhamento de Informações</strong><p>
                        O Inbravisa não compartilha suas informações pessoais com terceiros, exceto:
                        Fornecedores de Serviços: Podemos compartilhar informações com fornecedores de serviços contratados para realizar funções em nosso nome, como hospedagem de site, análise de dados e marketing.
                        Conformidade Legal: Quando necessário para cumprir com leis, regulamentos ou processos legais, ou para proteger os direitos, propriedade ou segurança do Inbravisa, de nossos usuários ou de terceiros.
                    </p></p><br />
                    <p><strong>4. Segurança das Informações </strong><p>

                        Adotamos medidas de segurança adequadas para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhum método de transmissão pela Internet ou armazenamento eletrônico é 100% seguro, e não podemos garantir a segurança absoluta.
                    </p></p><br />

                    <p><strong>5. Direitos dos Usuários </strong><p>
                        Você tem o direito de:
                        Acessar e Corrigir: Solicitar acesso às suas informações pessoais e corrigir quaisquer dados incorretos ou incompletos.
                        Excluir: Solicitar a exclusão de suas informações pessoais, salvo se estivermos obrigados a mantê-las por lei ou para cumprir nossos contratos.
                        Retirar Consentimento: Retirar seu consentimento para o processamento de suas informações a qualquer momento.
                        Para exercer seus direitos, entre em contato conosco pelo e-mail: inbravisa@inbravisa.com.br.
                    </p></p><br />

                    <p><strong>6. Cookies</strong><p>
                        Utilizamos cookies e tecnologias similares para melhorar a funcionalidade do nosso site e sua experiência de navegação. Você pode gerenciar suas preferências de cookies através das configurações do seu navegador.
                    </p></p><br />

                    <p> <strong>7. Alterações na Política de Privacidade </strong><p>
                        Reservamo-nos o direito de atualizar esta Política de Privacidade periodicamente. As alterações serão publicadas nesta página e, quando apropriado, notificaremos você por e-mail ou por meio de um aviso em nosso site.
                    </p></p><br />

                    <p><strong>8. Contato </strong><p>
                        Se você tiver dúvidas sobre esta Política de Privacidade ou sobre nossas práticas de proteção de dados, entre em contato conosco:

                        Inbravisa - Instituto Brasileiro de Auditoria em Vigilância Sanitária
                        CNPJ: 05.832.414/0001-09
                        Endereço: Av. Prof. Alfonso Bovero, 998 - cj 16
                        E-mail: inbravisa@inbravisa.com.br
                        Telefone: (11) 3672-7001

                        A sua privacidade é importante para nós. Agradecemos por confiar no Inbravisa.
                    </p></p><br />

                </div>
                <div className="p-4 border-t">
                    <button onClick={onClose} className="bg-blue-500 text-white px-4 py-2 rounded">
                        Fechar
                    </button>
                </div>
            </div>
        </div >
    );
};
