import React from "react";

export const Empresa = () => {
    return (
        <>
            <div className="p-2 md:p-10 md:rounded-lg md:shadow-lg">
                <div className="space-y-6 md:space-y-10 text-left">
                    {/* História */}
                    <h1 className="text-3xl md:text-4xl font-bold text-slate-800">Nossa História</h1>
                    {/* Corpo do Texto */}
                    <p className="text-base md:text-lg text-slate-600 leading-relaxed">
                        O INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância Sanitária, foi fundado em 2003 com o propósito principal de atuar como elo facilitador entre  as exigências da regulação sanitária
                        e os diversos setores regulados.
                    </p>
                    <p className="text-base md:text-lg text-slate-600 leading-relaxed">
                        Desenvolveu dentro do princípio de gestão regulatória  empresarial, uma metodologia própria que engloba materiais técnicos e estratégias eficazes.
                    </p>
                    

                    {/* Política de Qualidade */}
                    <h1 className="text-3xl md:text-4xl font-bold text-slate-800">Política de Qualidade</h1>
                    {/* Corpo do Texto */}
                    <p className="text-base md:text-lg text-slate-600 leading-relaxed">
                        O INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância Sanitária busca de forma permanente a excelência técnica dos serviços prestados para atender e superar 
                        as expectativas de seus clientes.                    </p>

                    
                    {/* Missão */}
                    <div className="space-y-4 md:space-y-5">
                        <h3 className="text-2xl md:text-3xl font-bold text-slate-800">Missão Institucional</h3>
                        <p className="text-base md:text-lg text-slate-600 leading-relaxed">
                            Promover a regularização de empresas e produtos com a transferência de conhecimento para que os setores regulados atinjam seus objetivos de forma consistente.
                        </p>
                        
                    </div>

                    {/* Visão */}
                    <div className="space-y-4 md:space-y-5">
                        <h3 className="text-2xl md:text-3xl font-bold text-slate-800">Visão</h3>
                        <p className="text-base md:text-lg text-slate-600 leading-relaxed">
                            Ser entidade de referência nacional na prestação de serviços na área de vigilância sanitária para empresas dos setores regulados e difundir os princípios sanitários para a população em geral.
                        </p>
                    </div>

                    {/* Valores */}
                    <div className="space-y-4 md:space-y-5">
                        <h3 className="text-2xl md:text-3xl font-bold text-slate-800">Valores</h3>
                        <ul className="list-disc list-inside text-base md:text-lg text-slate-600 leading-relaxed">
                            <li>Trabalho com seriedade e compromisso</li>
                            <li>Orientação de alto nível</li>
                            <li>Conhecimento técnico e credibilidade</li>
                            <li>Excelência na prestação de serviços</li>
                            <li>Ética e responsabilidade</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};