import react from "react";

import { Menu } from "../../../Components/Menu";




export const Certificacao = ()=>{
    return(
        <div className='mb-20'>
               <Menu /> 
               <br />
            </div>
    )
}