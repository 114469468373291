import React, { useState, useEffect } from "react";
import api from "../../config/configAPI";
import { Rodape } from "../../Components/Rodape";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { Menu } from "../../Components/Menu";
import { Menu as MenuAluno } from "../../Components/Menu_Aluno";
import { FaCheckCircle , FaPix } from "react-icons/fa6";


//componentes
import { CopiarTexto } from "../../Components/CopiarTexto";

export const PagamentoCartao = () => {
    const navigate = useNavigate();

    const token = localStorage.getItem('token');
    const cobranca = localStorage.getItem('IdCobranca');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pixData, setPixData] = useState({
        status: '',
        customer: ''
    });

    const headers = {
        'Content-Type': 'application/json'
    };

    const PagamentoCartao = async () => {
        try {
            const response = await api.get(`/StatusCompra?id=${cobranca}`, { headers });
            setPixData({
                status: response.data.status,
                payload: response.data.customer
            });
            cadPagamento();
            

            setIsLoading(false);
        } catch (error) {
            console.error("Erro ao processar pagamento:", error);
            setError('Erro ao processar pagamento. Tente novamente mais tarde.');
            setIsLoading(false);
        }
    };


    useEffect(() => {
        PagamentoCartao();
    }, []);


    const cadPagamento = async () => {
        const dadoscompra = {
            idCadastro: localStorage.getItem('idUser'),
            idcompra: localStorage.getItem('IdCobranca'),
            idAsaas: localStorage.getItem('Id Asaas'),
            Nome: localStorage.getItem('Nome'),
            idProtocolo: localStorage.getItem('idaula'),
            NomeProtocolo: localStorage.getItem('NomeAula'),
            ImagemProtocolo: localStorage.getItem('Img'),
            idCupom: localStorage.getItem('idCupom'),
            Nomecupom: localStorage.getItem('Cupom'),
            Status: pixData.status,
            Valor: localStorage.getItem('ValorCobrado'),
            Tipo: localStorage.getItem('TipoCobranca')
        };

        try {
            const response = await api.post('/CadComprar', dadoscompra, headers);
            console.log("Cadastro de compra realizado: ", response.data.mensagem);
        } catch (err) {
            console.error("Erro ao cadastrar compra: ", err);
        }
    };

    if (!token) {
        return <Navigate to="/login" />;
    }

    if (isLoading) {
        return <div>Carregando...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const formatCurrency = (value) => {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };

    const Finalizar = () => {
        

        navigate('/Area_Aluno');
    }



    return (
        <div>
            <div className="container mx-auto mt-8">
                <div className="mt-28">
                    {token ? <Menu /> : <MenuAluno />}
                </div>

                <div className="border border-blue-400 p-2">
                    <div className="w-full md:flex md:space-x-8 md:p-0">
                        <div className="w-full md:w-1/2 md:border-r border-blue-400 md:p-5">
                            <h2 className="text-lg font-bold mb-4">Scaneie o QR Code para efetuar o pagamento</h2>
                            <div className="border p-4 mb-8">
                                <div className="flex flex-row items-center justify-center">
                                    o status da compra é: {pixData.status}
                                </div>
                                <div className="mt-8"></div>
                                <div className="bg-slate-300 w-full items-center rounded-md p-5">
                                    <p className="flex flex-row justify-center text-xl text-bold">{formatCurrency(parseFloat(localStorage.getItem('ValorCobrado')))}</p>
                                </div>
                                <br />
                            </div>
                        </div>
                        {/* 
                        
                        <CopiarTexto texto={pixData.payload} />
                        */}

                        <div className="w-full md:w-1/2 justify-center">
                            <h2 className="text-lg font-bold mb-4">Pix Copia e Cola</h2>

                            <div className="flex mt-8">
                                <div className="break-words w-full">
                                    O status do pagamento é
                                    <p>{pixData.status === "PENDING" ? 'O pagamento está pendente por parte do banco' : ''} </p>
                                    <p>{pixData.status === "CONFIRMED" ? 'Compra Feita com Sucesso' : ''} </p>
                                    <p>{pixData.status === "PENDING" ? 'O pagamento está pendente por parte do banco' : ''} </p>
                                </div>

                            </div> <br />
                            <div className="flex flex-row items-center justify-center">
                                <div>
                                    
                                </div>
                              
                            </div>

                        </div>

                    </div>
                    <div className="flex ">
                        <button className="bg-emerald-500 text-white w-full p-2 m-2 rounded-md" onClickCapture={Finalizar}>
                            Finalizar
                        </button>
                    </div>

                </div>

                <br /><br /><br /><br /><br /><br /><br />
            </div>
            <div className="relative mt-10">
                <Rodape />
            </div>
        </div>
    );
};