import React from "react";
import { Menu } from '../../../Components/Menu';
import { Rodape } from "../../../Components/Rodape";
import { FaWhatsapp } from "react-icons/fa"; // Para adicionar um ícone ao link do WhatsApp

export const Treinamentos = () => {
    return (
        <>
            <div className="bg-slate-600 min-h-screen flex flex-col justify-between">
                <div className='mb-20'>
                    <Menu />
                    <br />
                </div>

                <div className="bg-slate-300 mx-10 my-5 p-8 rounded-2xl shadow-lg space-y-8">
                    {/* Título Principal */}
                    <h1 className="text-4xl font-bold text-slate-800 text-center border-b-2 border-indigo-700 pb-4">
                        Treinamentos INBRAVISA
                    </h1>

                    {/* Introdução */}
                    <p className="text-lg text-slate-600 leading-relaxed">
                        O Instituto Brasileiro de Auditoria em Vigilância Sanitária - INBRAVISA, oferece treinamentos especializados para aprimorar o conhecimento regulatório da sua equipe profissional.
                    </p>
                    <p className="text-lg text-slate-600 leading-relaxed">
                        Os treinamentos são ministrados de forma online e exclusiva para empresas com um mínimo de 5 participantes.
                    </p>

                    {/* Vantagens */}
                    <h2 className="text-3xl font-semibold text-slate-800 mt-6">Vantagens dos Treinamentos INBRAVISA</h2>
                    <ul className="list-disc list-inside text-lg text-slate-600 leading-relaxed pl-4">
                        <li>Instrutor ao vivo, permitindo esclarecimento de dúvidas em tempo real</li>
                        <li>Material didático incluso</li>
                        <li>Certificado de participação</li>
                        <li>Conformidade com a legislação de aprendizado contínuo</li>
                    </ul>

                    <p className="text-lg text-slate-600 leading-relaxed">
                        Com nossa equipe de especialistas, o INBRAVISA se torna um parceiro estratégico para ajudar sua empresa a cumprir as normas da Vigilância Sanitária com excelência.
                    </p>

                    {/* Seção: Como Funciona */}
                    <h2 className="text-3xl font-semibold text-slate-800 mt-6">Como Funciona</h2>
                    <p className="text-lg text-slate-600 leading-relaxed">
                        Após a contratação, sua empresa pode agendar a data do treinamento conforme sua conveniência. Um link exclusivo será enviado para sua equipe acessar a sala de treinamento.
                    </p>
                    <p className="text-lg text-slate-600 leading-relaxed">
                        Cada treinamento é exclusivo para colaboradores do CNPJ contratante, garantindo um ambiente seguro e privado.
                    </p>

                    {/* Contato */}
                    <div className="space-y-5">
                        <h3 className="text-3xl font-bold text-slate-800 mt-8">Como Contratar o Treinamento</h3>
                        <p className="text-lg text-slate-600 leading-relaxed">
                            Para contratar o treinamento ou obter mais informações, entre em contato conosco pelo WhatsApp. Será um prazer atender você!
                        </p>
                        <a
                            className="flex items-center justify-center bg-sky-900 rounded-lg text-blue-100 py-2 px-4 hover:bg-sky-700 transition duration-200 w-full max-w-xs mx-auto"
                            target="_blank"
                            href="https://api.whatsapp.com/send?phone=551136727001"
                            rel="noopener noreferrer"
                        >
                            <FaWhatsapp className="mr-2" /> Fale Conosco no WhatsApp
                        </a>
                    </div>
                </div>

                {/* Rodapé */}
                <Rodape />
            </div>
        </>
    );
};

