import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { FaRegCheckCircle, FaExclamation, FaRegFileExcel } from 'react-icons/fa';
import api from '../../config/configAPI';
import { Menu } from "../../Components/Menu";
import { StatusScreen } from '@mercadopago/sdk-react';
import { Pagamento } from "../../Components/Pagamento";





export const Retorno_Pagamento = () => {

    const idUser = localStorage.getItem('idUser');
    const idCompraMP = localStorage.getItem('idCompraMP');
    const Nome = localStorage.getItem('Nome');
    const idaula = localStorage.getItem('idaula');
    const ValorCompra = localStorage.getItem('ValorCompra');
    const idCupom = localStorage.getItem('idCupom');
    const Cupom = localStorage.getItem('Cupom');

    const { statuscompra } = useParams();

    const idProtocolo = localStorage.getItem('idaula');

    const [data, setData] = useState([
    ]);

    const [dataCompra, setDataCompra] = useState([
    ]);

    const [statusMsg, setStatusMsg] = useState({
        type: '',
        mensagem: ''
    })

    const [statusMsgCompra, setStatusMsgCompra] = useState({
        type: '',
        mensagem: ''
    })

    const [statusMsgPesquisa, setStatusMsgPesquisa] = useState({
        type: '',
        mensagem: ''
    })

    /* ----------------------------------------Verificar se já existe a Compra-----------------------------------------------------------------*/
useEffect(() => {
    const getPesqCompra = async () => {
        await api.get("/ComprasAdquiridas/" + idCompraMP)
            .then((response) => {
                if (response.data.user) {                   
                    console.log(response.data.user)
                    setStatusMsgPesquisa({
                        type: "False",
                        mensagem: "Já existe a compra"
                    })                    
                } else {
                    setStatusMsgPesquisa({
                        type: "error",
                        mensagem: "Compra não encontrada"
                    })
                }
            }).catch((err) => {
                if (err.response) {
                    setStatusMsgPesquisa({
                        type: "error",
                        mensagem: err.response.data.mensagem
                    })
                } else {
                    setStatusMsgPesquisa({
                        type: "error",
                        mensagem: "Erro. Tente mais tarde"
                    })
                }
            })
    }
    getPesqCompra()
},[])


    useEffect(() => {
        const getUser = async () => {
            await api.get("/Protocolo/" + idProtocolo,)
                .then((response) => {
                    if (response.data.user) {
                        setData(response.data.user)
                        //console.log(response.data.user)
                        localStorage.setItem('NomeAula', response.data.user.Nome)
                        localStorage.setItem('Img', response.data.user.LinkImagem)
                    } else {
                        setStatusMsg({
                            type: "error",
                            mensagem: "Erro. Usuário não encontrado"

                        })
                    }

                }).catch((err) => {
                    if (err.response) {
                        setStatusMsg({
                            type: "error",
                            mensagem: err.response.data.mensagem
                        })
                    } else {
                        setStatusMsg({
                            type: "error",
                            mensagem: "Erro. Tente mais tarde"
                        })
                    }
                })
        }
        getUser()
    }, [])

    const NomeAula = localStorage.getItem('NomeAula')
    const ImgAula = localStorage.getItem('Img')

    console.log("Nome da Aula : " + NomeAula)

    const cdpMP = idCompraMP

    const [comprar, setComprar] = useState({
        "idCadastro": '',
        "idcompra": '',
        "Nome": '',
        "idProtocolo": '',
        "NomeProtocolo": '',
        "ImagemProtocolo": '',
        "Valor": '',
        "IdCupom": '',
        "Nomecupom": '',
        "Status": '',
    });

    comprar.idCadastro = idUser;
    comprar.idcompra = idCompraMP ;
    comprar.Nome = Nome;
    comprar.idProtocolo = idaula;
    comprar.NomeProtocolo = NomeAula;
    comprar.ImagemProtocolo = ImgAula;
    comprar.Valor = ValorCompra;
    comprar.IdCupom = idCupom;
    comprar.Nomecupom = Cupom;
    comprar.Status = statuscompra == 'approved' ? 1 : statuscompra == 'in_process' ? 2 : statuscompra == 'rejected' ? 3 : 4;


console.log("O id no cdpMP "+comprar.Nome +" do Mercado pago é: " + cdpMP)

/* ----------------------------------------Cadastrar a Compra-----------------------------------------------------------------*/
    const cadCompra = async () => {
        await api.post('/CadComprar', comprar)
            .then((response) => {
                console.log(response.data.mensagem);
                setStatusMsgCompra({
                    type: 'Success',
                    mensagem: response.data.mensagem
                });

            }).catch((err) => {
                if (err.response) {
                    //console.log(err.response.data.mensagem);
                    setStatusMsgCompra({
                        type: 'Error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    //console.log('Erro: Tente Novamente por favor! ');
                    setStatusMsgCompra({
                        type: 'Error',
                        mensagem: 'Erro: Tente Novamente por favor! '
                    });

                }
            })
    }

    useEffect(() => {
        cadCompra();
    }, []);

    
    const initialization = {
        paymentId: idCompraMP, // id do pagamento a ser mostrado
         backUrls: {
            error: 'https://www.inbravisa.com.br/error',
            return: 'https://www.inbravisa.com.br/retorno',
          },
        visual: {
          showExternalReference: true
        }
    };

   
  
    const onError = async (error) => {
        // callback chamado para todos os casos de erro do Brick
        console.log(error);
    };
    const onReady = async () => {
        /*
          Callback chamado quando o Brick estiver pronto.
          Aqui você pode ocultar loadings do seu site, por exemplo.
       */
    };
 
    return (

        <div className="bg-slate-300 bg-cover">
            <Menu />

            <div class="flex flex-col-2 gap-4 justify-center items-center border-2 m-20 p-60 bg-slate-400">
                <div>
                    <StatusScreen
                        initialization={initialization}
                        onReady={onReady}
                        onError={onError}
                       
                    />
                </div>

                <div className="">
                    {
                        statuscompra == 'approved' ?
                            <div>
                                <div className="flex flex-row justify-center items-center bg-emerald-700 text-white rounded-md p-5">
                                    <p className="text-5xl"><FaRegCheckCircle /></p>
                                    <p></p>
                                    <p className="text-3xl "> Pagamento Aprovado </p>
                                </div>
                                <div className="flex flex-row justify-center items-center pt-10">
                                    <Link className="flex flex-row justify-center items-center bg-sky-700 text-white rounded-lg p-5" to='/Area_Aluno'>Ir para área do aluno</Link>
                                </div>



                            </div>
                            :
                            statuscompra == 'rejected' ?
                                <div>
                                    <div className="flex flex-row justify-center items-center bg-red-700  text-white rounded-md p-5">
                                        <p className="text-5xl"><FaRegFileExcel /></p>
                                        <p></p>
                                        <p className="text-3xl "> Pagamento Não Aprovado </p>
                                        <p><Pagamento /></p>
                                    </div>
                                    <div className="flex flex-row justify-center items-center pt-10">
                                        <Link className="flex flex-row justify-center items-center bg-sky-700  hover:bg-sky-500 text-white rounded-lg p-5" to='/Area_Aluno'>Ir para área do aluno</Link>
                                    </div>

                                </div>
                                :
                                statuscompra == 'in_process' || 'pending' ?
                                    <div>
                                        <div className="flex flex-row justify-center items-center bg-amber-600 text-white rounded-md p-5">
                                            <p className="text-5xl"><FaExclamation /></p>
                                            <p></p>
                                            <p className="text-3xl "> Pagamento Pendente </p>
                                        </div>
                                        <div className="flex flex-row justify-center items-center pt-10">
                                            <Link className="flex flex-row justify-center items-center bg-sky-700  hover:bg-sky-500 text-white rounded-lg p-5" to='/Area_Aluno'>Ir para área do aluno</Link>
                                        </div>

                                    </div>
                                    :
                                    <div>
                                        <div className="flex flex-row justify-center items-center bg-red-700  text-white rounded-md p-5">
                                            <p className="text-5xl"><FaRegFileExcel /></p>
                                            <p></p>
                                            <p className="text-3xl "> Pagamento Não Aprovado </p>
                                        </div>
                                        <div className="flex flex-row justify-center items-center pt-10">
                                            <Link className="flex flex-row justify-center items-center bg-sky-700 hover:bg-sky-500 text-white rounded-lg p-5" to='/Area_Aluno'>Ir para área do aluno</Link>
                                        </div>

                                    </div>
                    }
                </div>
            </div>


            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

        </div>
    )
}