import react, {createContext, useEffect, useState} from "react";
import api from '../config/configAPI';



const Context = createContext();



function AuthProvider({children}){

   

    const [authenticated, setAuthenticated] = useState(false);

    useEffect(()=>{
        const getLogin= async ()=>{
            const token = localStorage.getItem('token');

            if(token){
                api.defaults.headers.Authorization = `Bearer ${token}`
                setAuthenticated(true);
            };
        }
        getLogin();
    },[]);

    
    async function signIn(sit){
        setAuthenticated(true);
    }

    function handleLogout(){
        setAuthenticated(false);
        localStorage.removeItem("idUser");
        localStorage.removeItem("token");
        localStorage.removeItem("Nome");
        api.defaults.headers.Authorization = undefined;
        
        
    }


    return(
        <Context.Provider value={{authenticated, signIn, handleLogout}}>
            {children}
        </Context.Provider>
    );
}

export {Context,AuthProvider};
