import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Logo from '../../Assets/logo.png'; // Atualize o caminho para o logotipo

import { GrInstagram } from "react-icons/gr";
import { FaYoutube } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { TiThMenu } from "react-icons/ti";
import { FaWhatsapp } from "react-icons/fa";


export const Menu = ({ tokens, handleClose }) => {

  const token = localStorage.getItem('token');

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <nav className={`fixed top-0 left-0 w-full flex items-center justify-between py-4 px-6 z-50 transition-all duration-300 bg-gray-700 shadow-lg`}>

        {/* Logo à esquerda */}
        <div className="flex items-center text-white">
          <Link to="/"><img src={Logo} alt="INBRAVISA" className="h-18 mr-3 w-20 rounded-md" />
          </Link>
          <div className="flex flex-row gap-6 justify-center items-center ml-10">
            <a href='https://www.instagram.com/inbravisa/' target="blank">
              <GrInstagram className="text-xl text-white hover:text-purple-500 hover:bg-white hover:rounded-xl transition-colors duration-300" />
            </a>
            <a href='https://www.youtube.com/@INBRAVISA' target="blank">
              <FaYoutube className="text-xl text-white hover:text-red-500 hover:bg-white hover:rounded-xl transition-colors duration-300" />
            </a>

            <a href='https://www.facebook.com/profile.php?id=100011084343854' target="blank">
              <BsFacebook className="text-xl text-white hover:text-sky-700 hover:bg-white hover:rounded-xl transition-colors duration-300" />
            </a>


          </div>
        </div>

        {/* Botão de menu para mobile */}
        <div className="flex md:hidden items-center">
          <button onClick={toggleMenu} className="text-white">
            <TiThMenu size={28} />
          </button>
          <span onClick={toggleMenu} className="text-white cursor-pointer text-lg ml-2">Menu</span>
        </div>

        {/* Itens do menu à direita para desktop */}
        <div className="hidden md:flex items-center space-x-6 text-white">
          <Link to="/" className="hover:text-yellow-400 pl-5">Home</Link>
          <Link to="/Cursos" className="hover:text-yellow-400">Cursos Online</Link>
          <a href="/#servicos" className="hover:text-yellow-400">Serviços</a>
          <a href="#empresa" className="hover:text-yellow-400">Sobre a Empresa</a>
          <Link to="/institucional/quemsomos" className="hover:text-yellow-400">Equipe Profissional</Link>
          <a href="/#contato" className="hover:text-yellow-400">Contato</a>

          {/* Área do Aluno / Acessar */}
          {
            !token ? (
              <Link to="/login">
                <Button color="primary" variant="contained">Acessar</Button>
              </Link>
            ) : (
              <Link to="/Area_Aluno">
                <button className="text-emerald-900 bg-yellow-400 hover:bg-yellow-500 p-2 rounded-lg">Área do Aluno</button>
              </Link>
            )
          }
        </div>
      </nav>

      {/* Menu lateral para mobile */}
      <div className={`fixed top-0 right-0 bg-gray-800 w-64 h-full z-40 text-white transform transition-transform duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <ul className="mt-20 space-y-4 ml-5">
          <Link to="/" className="hover:text-yellow-400 pl-5">
            <li className="p-4 hover:bg-gray-700">Home</li>
          </Link>
          <Link to="/Cursos" className="hover:text-yellow-400"> <li className="p-4 hover:bg-gray-700">Cursos Online</li></Link>
          <a href="/#servicos" className="hover:text-yellow-400"> <li className="p-4 hover:bg-gray-700">Serviços</li></a>
          <a href="#empresa" className="hover:text-yellow-400"> <li className="p-4 hover:bg-gray-700">Sobre a Empresa</li></a>
          <Link to="/institucional/quemsomos" className="hover:text-yellow-400"> <li className="p-4 hover:bg-gray-700">Equipe Profissional</li></Link>
          <a href="/#contato" className="hover:text-yellow-400"> <li className="p-4 hover:bg-gray-700">Contato</li></a>
          <Link to="/" onClick={toggleMenu}>
          </Link>


          {/* Área do Aluno / Acessar */}
          {!token ? (
            <Link to="/login">
              <Button color="primary" variant="contained">Acessar</Button>
            </Link>
          ) : (
            <Link to="/Area_Aluno">
              <button className="text-emerald-900 bg-yellow-400 hover:bg-yellow-500 p-2 rounded-lg">Área do Aluno</button>
            </Link>
          )}
        </ul>
      </div>

      <a
        href="https://api.whatsapp.com/send?phone=551136727001"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-5 right-5 bg-green-500 hover:bg-green-600 text-white p-3 rounded-full shadow-lg transition-all duration-300 ease-in-out text-3xl hover:scale-110"
        aria-label="Entre em contato via WhatsApp"
      >
        <FaWhatsapp className="hover:scale-110" />
      </a>
    </div>
  );
};




