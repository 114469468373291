import React, { useState } from 'react';

export const CopiarBoleto = ({ texto }) => {
    const [copiado, setCopiado] = useState(false);

    const copiarTexto = () => {
        navigator.clipboard.writeText(texto)
            .then(() => {
                setCopiado(true);
                setTimeout(() => setCopiado(false), 3000);
            })
            .catch(err => console.error('Erro ao copiar texto: ', err));
    };

    return (
       

        
<div className="w-full md:w-1/2  border-blue-400 md:p-5">
<div>
    <h2 className="text-lg font-bold mb-4 flex items-center">
        Linha digitável do código de barras
    </h2>
</div>
<div className="border p-4 mb-8">
    <div className="flex flex-row items-center justify-center">
    <div className="bg-slate-300 w-full items-center rounded-md p-5">
        <p className="flex flex-row justify-center text-sm text-bold">{texto}</p>

    </div>


    </div>
    <div className="mt-8">

    </div>
    <button
                        className="bg-sky-500 p-5 items-center text-white rounded-lg w-96"
                        onClick={copiarTexto}
                    >
                        Copiar
                    </button>
    <br />
    <p> {copiado && <p className="text-green-500">Texto copiado!</p> }</p>
</div>
</div>



                           
    
  );
};