import React from "react";
import Logo from '../../Assets/logo.png';




export const Jumbo = () => {
    return (
        <div className="md:flex md:flex-row flex flex-col items-center p-5">
        <img 
            className="md:mr-10 md:w-[10%] md:max-w-none md:h-auto w-44 h-44 mb-4 md:mb-0 rounded-xl" 
            src={Logo} 
            alt="Logo INBRAVISA"
        />
        <div className="flex flex-col">
            <p className="text-xl text-justify mb-4">
                Agradecemos a sua visita ao nosso website. O INBRAVISA – Instituto Brasileiro de Auditoria em Vigilância Sanitária é uma entidade privada,
                fundada em 2003, especializada em serviços de regulação sanitária, nossa equipe altamente qualificada oferece uma ampla gama de serviços na área de regulação sanitária.
            </p>
            <p className="text-xl text-justify">
                Todos os membros da equipe do INBRAVISA atendem rigorosamente aos requisitos estabelecidos pela Agência Nacional de Vigilância Sanitária (ANVISA)
                para consultores. Recentemente, desenvolvemos os Protocolos Técnicos Sanitários, que combinam videoaulas, mentorias de apoio,
                material didático exclusivo e certificados de participação, com o objetivo de disseminar conhecimento a diversos segmentos abrangidos
                pela legislação sanitária.
            </p>
        </div>
    </div>
    )
}