import React, { useState } from 'react';
import { Card } from '../../Components/Card';
import { Menu } from '../../Components/Menu';
import { Jumbo } from '../../Components/Descricao';
import { Rodape } from "../../Components/Rodape";
import { Servicos } from '../../Components/Servicos';
import { Social } from '../../Components/Social';
import { Empresa } from '../../Components/Empresa';
import { WhatsappBtn } from '../../Components/Whatsapp';
import { Carrossel } from '../../Components/Carrossel';

import { Listar } from "../../Components/Listar";

import { CookieConsent } from '../../Components/CookieConsent';


//Carrossel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Contato } from '../../Components/Contato';


function sair() {

    //console.log("Saiu")
    //localStorage.removeItem("idUser");
    //localStorage.removeItem("token");
    //localStorage.removeItem("Nome");



}



export const Home = () => {
    localStorage.removeItem('ValorCobrado');
    localStorage.removeItem('ValorCompra');
    localStorage.removeItem('StatusCobranca');
    localStorage.removeItem('TipoCobranca');
    localStorage.removeItem('IdCobranca');
    localStorage.removeItem('Img');
    localStorage.removeItem('idaula');
    localStorage.removeItem('NomeAula');


    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };



    return (
        <>

            <div>
                <div className='mb-20'>
                    <Menu />
                    <br />
                </div>



                <section>
                    <div className="bg-slate-300 p-5 flex ml-2 mr-2  mt-2 justify-center ">
                        <Carrossel />

                    </div>

                </section>
                <section>
                    <div className="bg-slate-300 p-5 flex ml-2 mr-2  mt-5 justify-center ">
                        <Jumbo />

                    </div>
                </section>

                <section id='servicos'>
                    <div className="bg-slate-300 p-5 flex ml-2 mr-2  mt-5 justify-center">
                        <div className='flex flex-col text-lg items-center'>
                            <p className='mb-5 border-b-indigo-900 border-b-2 text-5xl pb-5'>Nossos Serviços</p>
                            <Servicos />
                        </div>
                    </div>
                </section>

                <section id="empresa">

                    <div className="bg-slate-300 p-5 flex ml-2 mr-2  mt-5 justify-center">
                        <div className='flex flex-col text-lg items-center'>
                            <p className='border-b-indigo-900 border-b-2 text-5xl pb-5'>Nossa Empresa</p>
                            <Empresa />
                        </div>
                    </div>

                </section>

                <section id="contato">

                    <div className="bg-slate-300 p-5 flex ml-2 mr-2  mt-5 justify-center">
                        <div className='flex flex-col text-lg items-center'>
                            <p className='border-b-indigo-900 border-b-2 text-5xl pb-5'>Nossos Contatos</p>
                            <Contato />
                        </div>
                    </div>

                </section>
{/*
                <section>
                    <div className="bg-slate-300 p-5 flex ml-2 mr-2  mt-5 justify-center">
                        <div className='flex flex-col text-lg items-center'>
                            <p className='mb-5 border-b-indigo-900 border-b-2 text-5xl pb-5'>Nossas Mídias Sociais</p>
                            <Social />
                        </div>
                    </div>
                </section>
*/}

                <div>
                    <CookieConsent />
                </div>
                <div className="relative">
                    {/* Outros componentes e conteúdo da página */}
                    <WhatsappBtn />
                </div>

            </div>
            <div class=" mt-10">
                <Rodape />

            </div>
        </>
    )
}