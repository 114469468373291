import React from "react";

//icones
import { FaBarcode, FaPix } from "react-icons/fa6";

const PaymentOption = ({ label, onSelect, icon: Icon}) => {
  return (
    <div className="mb-4">
      <label className="flex items-center cursor-pointer m-5">
        <input
          type="radio"
          className="mr-2 leading-tight"
          name="paymentOption"
          onChange={() => onSelect(label)}
          
        />
        {Icon && <Icon className="mr-2" />} {/* Renderiza o ícone se estiver presente */}
        <span className="text-sm">{label}</span>
      </label>
    <hr/>
    </div>
    
  );
};

export default PaymentOption;