import react from "react";

import { Menu } from "../../../Components/Menu";
import { Rodape } from "../../../Components/Rodape";




export const Contato = () => {
    return (

        <>
            <div className="bg-slate-600 bg-cover">
                <div className='mb-20'>
                    <Menu />
                    <br />
                </div>
                


                    <div className="bg-slate-300 mt-5 mb-5 ml-10 mr-10 p-5 rounded-xl">
                        <div className="grid grid-rows-2">
                            <p className="text-3xl text-center">Estes são os nossos contatos</p>
                        </div>
                    </div>





                    <div className="bg-slate-200 mx-10 mb-10 rounded-2xl p-10 shadow-lg">
                        <div className="grid xl:grid-cols-3 grid-cols-1 gap-8 min-h-[500px]">

                            <div className="p-5 mb-5 rounded-2xl shadow-md hover:shadow-xl transition-shadow duration-300 min-h-[500px]">
                                <div className="flex justify-center">
                                    <img className="rounded-full hover:rotate-2" src="https://img.freepik.com/vetores-premium/logo-do-google-maps_578229-304.jpg" width="80" height="80" />
                                </div>
                                <p className="text-5xl text-center font-bold pt-2 pb-3 text-gray-800">
                                    Localização
                                </p>

                                <hr className="border border-blue-900 my-3" />
                                <p className="text-justify text-gray-700 leading-relaxed">
                                    Estamos Localizados na Av. Prof. Alfonso Bovero, 998 - Cj 16

                                </p>
                                <div className="mt-5">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.8598113527823!2d-46.6866842238844!3d-23.537544060719064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce57ef5053a95d%3A0xb7b06c878f2ad02c!2sINBRAVISA%20-%20Instituto%20Brasileiro%20de%20Auditoria%20em%20Vigil%C3%A2ncia%20Sanit%C3%A1ria!5e0!3m2!1spt-BR!2sbr!4v1729135384042!5m2!1spt-BR!2sbr"
                                        width="100%"
                                        height="300"
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade">
                                    </iframe>
                                </div>
                            </div>

                            <div className="p-5 mb-5 rounded-2xl shadow-md hover:shadow-xl transition-shadow duration-300 min-h-[500px]">
                                <div className="flex justify-center">
                                    <img className=" hover:rotate-2" src="https://cdn-icons-png.flaticon.com/512/126/126341.png" width="80" height="80" />
                                </div>
                                <p className="text-5xl text-center font-bold pt-2 pb-3 text-gray-800">
                                    Telefone e Email
                                </p>

                                <hr className="border border-blue-900 my-3" />
                                <p className="text-justify text-gray-700 leading-relaxed">
                                    <p className=" text-justify">
                                        Você pode entrar facilmente em contato conosco nos telefones:
                                        <p> (11) 3672-7001 | (11) 99415-3378</p>
                                        <p>
                                            Nosso email é:
                                            <a href="mailto:inbravisa@inbravisa.com.br" className="text-blue-500 hover:underline ml-1">
                                                inbravisa@inbravisa.com.br
                                            </a>
                                        </p>
                                    </p>
                                </p>
                            </div>

                            <div className="p-5 mb-5 rounded-2xl shadow-md hover:shadow-xl transition-shadow duration-300 min-h-[500px]">
                                <div className="flex justify-center">
                                    <img className="rounded-full hover:rotate-2" src="https://img.freepik.com/vetores-premium/conceito-de-icone-do-whatsapp_23-2147897840.jpg" width="80" height="80" />
                                </div>
                                <p className="text-5xl text-center font-bold pt-2 pb-3 text-gray-800">
                                    Whatsapp
                                </p>

                                <hr className="border border-blue-900 my-3" />
                                <p className="text-justify text-gray-700 leading-relaxed">
                                    <a className="flex justify-center bg-emerald-500 text-white p-3 rounded-md" target="_blank" href="https://api.whatsapp.com/send?phone=551136727001">Clique aqui</a>
                                </p>
                            </div>
                        </div>






                    </div>


                    <div class=" mt-10">
                        <Rodape />
                    </div>
                </div>
            
        </>
    )
}