import React from "react";
import { GrInstagram } from "react-icons/gr";
import { FaYoutube } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";

export const Rodape = () => {

  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-slate-900 text-white w-full p-5 bottom-0">
      <div className="container mx-auto flex flex-col space-y-4 justify-center items-center text-center text-sm md:flex-row md:justify-between">
        {/* Informações INBRAVISA */}
        <div>
          <h1>Sociais</h1>
          <div className="flex flex-row gap-6 justify-center items-center ml-10">
            <a href='https://www.instagram.com/inbravisa/' target="blank">
              <GrInstagram className="text-xl md:text-4xl text-white hover:text-purple-500 hover:bg-white rounded-full transition-colors duration-300 p-1" />
            </a>
            <a href='https://www.youtube.com/@INBRAVISA' target="blank">
              <FaYoutube className="text-xl md:text-4xl text-white hover:text-red-500 hover:bg-white rounded-full transition-colors duration-300 p-1" />
            </a>

            <a href='https://www.facebook.com/profile.php?id=100011084343854' target="blank">
            <BsFacebook className="text-xl md:text-4xl text-white hover:text-sky-700 hover:bg-white rounded-full transition-colors duration-300 p-1" />

            </a>


          </div>
        </div>
        
        <div>
          <h2 className="font-semibold text-lg mb-2">INBRAVISA</h2>
          <p>Instituto Brasileiro de Auditoria em Vigilância Sanitária</p>
          <p>CNPJ: 05.832.414/0001-09</p>
          <p>Av. Prof. Alfonso Bovero, 998 - Cj 16 - Perdizes - São Paulo-SP</p>
          <p>Copyright® {currentYear}</p>
        </div>


        {/* Contatos */}
        <div className="flex flex-col space-y-2">
          <h2 className="font-semibold text-lg mb-2">Contato</h2>
          <p>
            <a
              className="text-blue-400 hover:underline"
              target="_blank"
              href="https://api.whatsapp.com/send?phone=551136727001"
              rel="noopener noreferrer"
            >
              Telefone e WhatsApp: (11) 3672-7001
            </a>
          </p>
          <p>
            <a href="mailto:inbravisa@inbravisa.com.br" className="text-blue-400 hover:underline">
              inbravisa@inbravisa.com.br
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};