import React from "react";
import { Menu } from '../../../Components/Menu';
import { Rodape } from "../../../Components/Rodape";

export const Mentoria = () => {
    return (
        <>
            <div className="bg-slate-600 bg-cover">
                <div className='mb-20'>
                    <Menu />
                    <br />
                </div>

                <div className="bg-slate-300 mt-5 mb-5 mx-10 p-5 rounded-2xl shadow-lg">
                    <div className="space-y-10 text-center xl:text-left">
                        {/* História */}
                        <h1 className="flex text-4xl font-bold text-slate-800 justify-center border-b-2 border-b-indigo-700 pb-2">Mentoria INBRAVISA</h1>
                        {/* Corpo do Texto */}
                        <p className="text-lg text-slate-600 leading-relaxed">
                            O Instituto Brasileiro de Auditoria em Vigilância Sanitária - INBRAVISA, proporciona mentoria para empresas que queiram orientações de como proceder para obter a sua documentação,
                            além de entender quais são seus direitos e obrigações perante à ANVISA e as vigilâncias sanitárias locais.
                        </p>
                        <p className="text-lg text-slate-600 leading-relaxed">
                            Portanto o INBRAVISA, apresenta-se como aliado à sua atividade para decifrar e aplicar as diretrizes impostas pela Vigilância Sanitária.
                            Orientando com experiência e conhecimento, nossa equipe técnica está apta para fazer a sua atividade estar dentro da legislação sanitária.
                        </p>

                        {/* Título Principal */}
                        <h1 className="text-4xl font-bold text-slate-800">Como Funciona</h1>
                        {/* Corpo do Texto */}
                        <p className="text-lg text-slate-600 leading-relaxed">
                            Ao contratar a Mentoria do INBRAVISA, o empresário terá direito à reuniões semanais onde receberá orientações de como proceder com a documentação da empresa,
                            as adequações necessárias das áreas físicas e explicações sobre fluxos e materiais utilizados.
                        </p>

                        {/* Missão */}
                        <div className="space-y-5">
                            <h3 className="text-3xl font-bold text-slate-800">Como Contratar a Mentoria do INBRAVISA</h3>
                            <p className="text-lg text-slate-600 leading-relaxed">
                                Para a contratação da mentoria ou demais explicações, entre em contato conosco pelo WhatsApp.
                            </p>
                            <br />
                            <a
                                className="bg-sky-900 rounded-lg text-blue-100 p-2 hover:bg-sky-700 ml-2 mr-2"
                                target="_blank"
                                href="https://api.whatsapp.com/send?phone=551136727001"
                                rel="noopener noreferrer"
                            >
                                Clique Aqui
                            </a>
                            <p>Será um prazer conversar com você.</p>
                        </div>
                    </div>
                </div>
                
                {/* Rodapé sem margem superior excessiva */}
                <div>
                    <Rodape />
                </div>
            </div>
        </>
    );
};
