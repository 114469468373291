import react from "react";

import { Menu } from "../../../Components/Menu";
import { Rodape } from "../../../Components/Rodape";
import { Empresa } from "../../../Components/Empresa";

var ano_atual = new Date().getFullYear();
var ano_informado = 2003;
console.log(ano_atual - ano_informado);



export const SobreEmpresa = () => {
    return (
        <>
            <div className='mb-20'>
                <Menu />
                <br />
            </div>
            <div className="bg-slate-300 p-5 flex ml-2 mr-2  mt-2 justify-center">
                    <div className='flex flex-col text-lg items-center'>
                        <p className='mb-5 border-b-indigo-900 border-b-2 text-5xl'>Nossa Empresa</p>
                        <Empresa />
                    </div>
                </div>

            <div class="relative mt-10">
                <Rodape />
            </div>

        </>
    )
}