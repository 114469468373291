import React from "react";
import Modal from 'react-modal';

//import Modal from "../../Components/Modal";

Modal.setAppElement('#root');


export const Janela = ({nomebtn, nome, whats, email, endereco, cidade, uf, cep}) => {   
    
     // Hook que demonstra se a modal está aberta ou não
  const [modalIsOpen, setIsOpen] = React.useState(false);

  // Função que abre a modal
  function abrirModal() {
    setIsOpen(true);
  }

  // Função que fecha a modal
  function fecharModal() {
    setIsOpen(false);
  }

    
    
    
    return(
        <div>
        <button className="rounded-md bg-gray-500 p-1 text-white pr-2 pl-2 m-1" onClick={abrirModal}>{nomebtn}</button>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={fecharModal}
          contentLabel="Modal de exemplo"
        >
           <div className="flex justify-end ">
            <button className="bg-gray-400 p-1 text-white hover:bg-gray-800 flex-col pr-2 pl-2 mb-5" onClick={fecharModal}>X</button>
          </div>
            <div className="flex justify-center mb-5">
            <p className="text-2xl"> {nome}</p>
          </div>
         
          
          <hr />
          <div className="pt-5 pl-10 flex-row">
             
                <p> {whats} </p>
                <p> {email}</p>
                <p> {endereco} {cidade}{uf}</p>
                <p> {cep} </p>
                
                
          </div>
         
          
        </Modal>
        
        </div>
        )

 }