import React, { useEffect, useState } from "react";
import api from "../config/configAPI";

export const FiltroAssistida = ({ num }) => {
  const [assistida, setAssistida] = useState({ count: 0 });
  const [server2, setServer2] = useState({ type: '', mensagem: '' });
  const [server3, setServer3] = useState({ type: '', mensagem: '' });

  // Listar Quantidade Aulas por Id de Aluno e Id de Aula
  const AulaAssistida = async () => {
    try {
      const response = await api.get("/Assistidas/" + localStorage.getItem('idUser') + "/" + num);
      console.log(response);
      setAssistida(response.data.user);
    } catch (err) {
      if (err.response) {
        setServer2({ type: 'Erro', mensagem: err.response.data.mensagem });
      } else {
        setServer2({ type: 'Erro', mensagem: 'Conexão com o servidor perdida' });
      }
    }
  };

  // Verificar Certificado
  const checkCertificado = async () => {
    try {
      const response = await api.get(
        `checkCertificado/${localStorage.getItem('idUser')}/${localStorage.getItem('idProduto')}`
      );
      console.log('Resposta do Servidor ', response.data);

      // Atualize o estado ou execute qualquer lógica adicional com a resposta aqui
      if (response.data.exists) {
        setServer3({ type: 'Sucesso', mensagem: 'Certificado encontrado!' });
      } else {
        setServer3({ type: 'Erro', mensagem: 'Certificado não encontrado.' });
      }
    } catch (err) {
      if (err.response) {
        setServer3({ type: 'Erro', mensagem: err.response.data.mensagem });
      } else {
        setServer3({ type: 'Erro', mensagem: 'Conexão com o servidor perdida' });
      }
    }
  };

  useEffect(() => {
    AulaAssistida();
    checkCertificado();
  }, []);

  return (
    <span>
      {assistida.count > 0 ? (
        <div>
          {/* 
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="border bg-emerald-700 border-emerald-900 ml-5 mr-5 p-2 text-white"
            href='/Certificado'
          >
            Certificado
          </a>
          */}
          <span className="rounded-full bg-emerald-800 text-white pr-2 pl-2">
            {assistida.count}
          </span>
          
          {server3.type === 'Erro' && (
            <div className="text-red-500 mt-2">  {server3.mensagem}</div>
          )}
        </div>
      ) : (
        <span className="rounded-full bg-red-800 text-white pr-2 pl-2">
          Não Assistida
        </span>
      )}
    </span>
  );
};