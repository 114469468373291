import React, { useEffect, useState } from "react";
import api from "../../config/configAPI";
import { Link, useParams } from "react-router-dom";
import { Menu } from "../../Components/Menu_Aluno";
import { FaPlay } from "react-icons/fa";
import { FiltroAssistida } from "../../Filtros";

export const Listar_Aulas = () => {
  const { idProtocolo } = useParams();
  const [data, setData] = useState([]);
  const [protocolo, setProtocolo] = useState({});
  const [server, setServer] = useState({ type: "", mensagem: "" });

  // Obter nome do protocolo
  const nomeProtocolo = async () => {
    try {
      const response = await api.get(`/Protocolo/${idProtocolo}`);
      setProtocolo(response.data.user);
    } catch (err) {
      setServer({
        type: "Erro",
        mensagem: err.response?.data?.mensagem || "Conexão com o servidor perdida",
      });
    }
  };

  // Obter lista de vídeos do protocolo
  const verProtocolo = async () => {
    try {
      const response = await api.get(`/ListVideos/${idProtocolo}`);
      setData(response.data.users);
    } catch (err) {
      setServer({
        type: "Erro",
        mensagem: err.response?.data?.mensagem || "Conexão com o servidor perdida",
      });
    }
  };

  useEffect(() => {
    nomeProtocolo();
    verProtocolo();
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Menu */}
      <Menu />
      
      {/* Cabeçalho */}
      <div className="container mx-auto py-8 mt-20">
        <div className="bg-green-800 text-white p-5 text-center rounded-lg shadow-md">
          <h1 className="text-3xl font-bold">{protocolo.Nome}</h1>
        </div>

        {/* Botão Certificado */}
        <div className="flex justify-end m-4">
          <Link to="/Certificado" target="_blank">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300">
              📜 Certificado
            </button>
          </Link>
        </div>

        {/* Lista de Aulas */}
        <div className="mt-6">
          {data.length === 0 ? (
            <p className="text-center text-gray-600">Nenhuma aula encontrada.</p>
          ) : (
            data.map((user) => (
              <div
                key={user.id}
                className="bg-white p-5 flex items-center justify-between rounded-lg shadow-md mb-4 hover:shadow-lg transition duration-300"
              >
                {/* Nome da Aula */}
                <span className="text-lg font-medium text-gray-800">{user.NomeAula}</span>

                {/* Botões de Ação */}
                <div className="flex items-center space-x-4">
                  <FiltroAssistida num={user.id} />
                  <FaPlay className="text-green-600" />
                  <Link
                    className="text-blue-500 hover:text-blue-700 font-medium"
                    to={`/Assistir/${user.id}/${user.NomeAula}/${user.idProtocolo}`}
                  >
                    Assistir
                  </Link>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
