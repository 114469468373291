import React from 'react';
import { FaWhatsapp } from "react-icons/fa";

export const WhatsappBtn = () => {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=551136727001"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-5 right-5 bg-green-500 hover:bg-green-600 text-white p-3 rounded-full shadow-lg transition-all duration-300 ease-in-out text-3xl"
      aria-label="Entre em contato via WhatsApp"
    >
     <FaWhatsapp/>
    </a>
  );
};

