import react from "react";

import { Menu } from "../../../Components/Menu";
import { Rodape } from "../../../Components/Rodape";




export const Quemsomos = () => {
    return (
        <>
        <div className="bg-slate-500 bg-cover">
            <div className="mb-20">
                <Menu />
                <br />
            </div>

            <div className="bg-slate-300 mt-5 mb-5 ml-10 mr-10 p-8 rounded-xl shadow-md">
                <div className="grid grid-rows-2">
                    <p className="text-4xl font-semibold text-center text-gray-800">
                        Conheça Nossa Equipe Profissional
                    </p>
                </div>
            </div>

            <div className="bg-slate-200 mx-10 mb-10 rounded-2xl p-10 shadow-lg">
                <div className="grid xl:grid-cols-3 grid-cols-1 gap-8 min-h-[500px]">

                    <div className="p-5 mb-5 rounded-2xl shadow-md hover:shadow-xl transition-shadow duration-300 min-h-[500px]">
                        <div className="flex justify-center">
                            <img className="rounded-full hover:rotate-2 transition-transform duration-300 ease-in-out shadow-md"
                                src="https://www.inbravisa.com.br/imagens/fotoRui.jpg" width="160" height="160" />
                        </div>
                        <p className="text-5xl text-center font-bold pt-2 pb-3 text-gray-800">
                            Rui de Andrade Dammenhain
                        </p>
                        <p className="text-xl text-center text-gray-700">Diretor Presidente</p>
                        <hr className="border border-blue-900 my-3" />
                        <p className="text-justify text-gray-700 leading-relaxed">
                            <ul><li>Especialista Latu Sensu em Vigilância Sanitária;</li>
                                <li>Especialista Latu Sensu em Saúde Pública, com ênfase em Saúde Coletiva;</li>
                                <li>Pós-Graduação em Planejamento Estratégico Situacional pela FUNDAP/SP;</li>
                                <li>Pós-Graduação em Capacitação Pedagógica e treinamento pelo Centro Formador de Pessoal para Saúde de São Paulo (CEFOR-SP)</li>
                                <li>Diretor do Grupo Técnico Odontológico do Centro de Vigilância Sanitária (CVS/SP);</li>
                                <li>Diretor Substituto da Divisão de Serviços de Saúde do Centro de Vigilância Sanitária (CVS/SP);</li>
                                <li>Diretor do Grupo Técnico de Vigilância Sanitária da Direção Regional de Saúde da Capital da Secretaria do Estado da Saúde;</li>
                                <li>Coordenador da Equipe de Auditoria Sanitária Hospitalar junto ao Departamento de Convênios e Assistência Médica (DECAM) do Instituto de Assistência Médica ao Servidor Público Estadual (IAMSPE) </li>
                            </ul>
                        </p>
                    </div>

                    <div className="p-5 mb-5 rounded-2xl shadow-md hover:shadow-xl transition-shadow duration-300 min-h-[500px]">
                        <div className="flex justify-center">
                            <img className="rounded-full hover:rotate-2 transition-transform duration-300 ease-in-out shadow-md"
                                src="https://www.inbravisa.com.br/imagens/fotoValerianova.png" width="160" height="160" />
                        </div>
                        <p className="text-5xl text-center font-bold pt-2 pb-3 text-gray-800">
                            Valeria Camargo Dammenhain
                        </p>
                        <p className="text-xl text-center text-gray-700">Diretora Técnica</p>
                        <hr className="border border-blue-900 my-3" />
                        <p className="text-justify text-gray-700 leading-relaxed">
                            <ul>
                                <li>Especialista Latu Sensu em Saúde Pública, com ênfase em Administração de Serviços de Saúde;</li>
                                <li>Avaliadora do Sistema ONA – Organização Nacional de Acreditação;</li>
                                <li>Assistente Técnica de Direção e Planejamento do Núcleo Regional de Saúde 1 (SES/SP);</li>
                                <li>Diretora Técnica de diversas unidades de saúde da Secretaria do Estado da Saúde de São Paulo.</li>
                            </ul>
                        </p>
                    </div>

                    <div className="p-5 mb-5 rounded-2xl shadow-md hover:shadow-xl transition-shadow duration-300 min-h-[500px]">
                        <div className="flex justify-center">
                            <img className="rounded-full hover:rotate-2 transition-transform duration-300 ease-in-out shadow-md"
                                src="https://www.inbravisa.com.br/imagens/fotoVitor.jpeg" width="160" height="160" />
                        </div>
                        <p className="text-5xl text-center font-bold pt-2 pb-3 text-gray-800">
                            Vitor Camargo Dammenhain
                        </p>
                        <p className="text-xl text-center text-gray-700">Diretor Administrativo</p>
                        <hr className="border border-blue-900 my-3" />
                        <p className="text-justify text-gray-700 leading-relaxed">
                            <ul>
                                <li>Administrador de empresas;</li>
                                <li>Pós-Graduação em Vigilância em Saúde pelo Instituto Federal de Educação, Ciência e Tecnologia do Rio Grande do Sul (IFRS/RS)</li>
                                <li>Pós-Graduação Gestão de micro e pequena empresa;</li>
                                <li>MBA em gestão dos serviços de saúde;</li>
                                <li>Pós-Graduação Latu Sensu Análise e Desenvolvimento de Sistemas</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>

            
            

            <div className="bg-slate-300 mt-5 mb-5 ml-10 mr-10 p-5 rounded-xl shadow-md">
                <div className="grid grid-rows-2">
                    <p className="text-2xl text-center text-gray-800">
                        Além disso, o INBRAVISA conta com uma gama Profissional nas mais diversas áreas para as suas melhores soluções
                    </p>
                </div>
            </div>

            <div className="mt-10">
                <Rodape />
            </div>
        </div>

</>

    )
}