import React, { useState, useEffect } from "react";
import api from "../../config/configAPI";
import { Link, useParams } from "react-router-dom";
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { Payment } from '@mercadopago/sdk-react';
import { Menu } from "../../Components/Menu"
import { Menu as MenuAluno } from "../../Components/Menu_Aluno"


initMercadoPago('TEST-b44d98f8-c386-4479-b542-1b64bfb196d2');



export const Comprar = () => {


  const token = localStorage.getItem('token');

  const { idProduto } = useParams();

  const [compra, setCompra] = useState();

  const [cupom, setCupom] = useState({
    "txtCupom": '',
  });

  const [status, setStatus] = useState({
    type: '',
    mensagem: ''
  })

  const [data, setData] = useState([
  ]);

  const valueInput = e => setCupom({ ...cupom, [e.target.name]: e.target.value });

  const ativarCupom = async e =>{
    e.preventDefault();
    console.log('Valor do Cupom: ' + cupom);

  }


  useEffect(() => {
    const getUser = async () => {
      await api.get("/ComprarItem/" + idProduto,)
        .then((response) => {
          if (response.data.user) {
            setData(response.data.user)
          } else {
            setStatus({
              type: "error",
              mensagem: "Erro. Usuário não encontrado"
            })
          }
        }).catch((err) => {
          if (err.response) {
            setStatus({
              type: "error",
              mensagem: err.response.data.mensagem
            })
          } else {
            setStatus({
              type: "error",
              mensagem: "Erro. Tente mais tarde"
            })
          }
        })
    }
    getUser()
  }, [idProduto])

  const [produto, setProduto] = useState({
    "title": data.Nome,
    "unit_price": data.Promocao !== 1 ? data.Valor : data.Valor_Desconto,
    "quantity": 1

  });


  const initialization = {
    amount: data.Promocao !== 1 ? data.Valor : data.Valor_Desconto,
    //preferenceId: "http://localhost:3006/create_preference",
  };
  const customization = {
    paymentMethods: {
      ticket: "all",
      bankTransfer: "all",
      creditCard: "all",
      debitCard: "all",
      mercadoPago: "all",
    },
  };
  
  const onSubmit = async (
    { formData }
  ) => {
    // callback chamado ao clicar no botão de submissão dos dados
    return new Promise((resolve, reject) => {
      fetch("https://api.mercadopago.com/v1/payments", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'Bearer TEST-7321413889744263-071314-75f6d33e2dcb72dc5f190f4a99d57c1a-306070152'
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((response) => {
          // receber o resultado do pagamento
          resolve();
          console.log(response)
          setCompra(response.id)
          console.log('Resultado do id da compra - '+ setCompra)
        })
        .catch((error) => {
          // lidar com a resposta de erro ao tentar criar o pagamento
          reject();
        });
    });
  };
  const onError = async (error) => {
    // callback chamado para todos os casos de erro do Brick
    console.log(error);
  };
  const onReady = async () => {
    /*
      Callback chamado quando o Brick estiver pronto.
      Aqui você pode ocultar loadings do seu site, por exemplo.
    */
  };


  console.log(produto)


  fetch('/create_preference', produto)
    .then((res) => {
      console.log(res);

    }).catch((error) => {
      if (error.response) {
        console.log(error.response.data.mensagem);

      } else {
        //console.log('Erro: Tente Novamente por favor! ');
        setStatus({
          type: 'Error',
          mensagem: 'Erro: Tente Novamente por favor! '
        });

      }
    })


  return (
    <div className="bg-slate-500 h-auto mb-20">
      {
        token === ''
          ?
          <MenuAluno />
          :
          <Menu />

      }
      <div className="bg-slate-200 text-xl rounded-lg border border-1 border-sky-700 p-5 text-center ml-20  mt-20 mr-20">
        <p className=""> As transações são feitas pelo Mercado Pago, as informações de pagamentos NÃO SÃO SALVAS em nossa plataforma para a sua maior segurança.</p>
      </div>
      <div className=" max-h-full p-5 pl-10 pr-10 grid grid-cols-2 gap-4 justify-center items-center ">



        <div className="bg-slate-200 text-xl rounded-lg border border-1 border-sky-700 items-center text-left p-10 grid grid-cols-2 gap-4">
          <div><img className=" w-64 h-52 " src={data.LinkImagem}></img></div>
          <div>

            Produto: {data.Nome}<br />
            {data.Promocao !== 1 ?
              <p  className="bg-emerald-600 text-white p-2">Valor: R${data.Valor},00 à vista</p>
              :
              <div>
              <p className="line-through text-red-600">De R${data.Valor},00 </p>
              <p  className="bg-emerald-600 text-white p-2">Valor: R${data.Valor_Desconto},00 à vista</p>
              </div>
            }
            <br />
            <p>Com o seu cartão de crédito você poderá parcelar em até 12x.</p>
            <br/>
            <br/>

            <div className="bg-slate-300 w-full items-center rounded-md p-5">
              <p className="text-center">Possuí Cupom de indicação?</p>
              <form onSubmit={ativarCupom}>
              <input className=" mt-5 mb-5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="txtCupom" onChange={valueInput} placeholder="Digite o Cupom" />
                
                <button type='submit' className=" w-full hover:bg-sky-800 bg-sky-500 border broder-1 rounded-lg text-white p-2">Usar Cupom</button>
              </form>
            </div>
          </div>
        </div>
        

        <div className="">
          <Payment
            initialization={initialization}
            customization={customization}
            onSubmit={onSubmit}
            onReady={onReady}
            onError={onError}
          />


        </div>

      </div>



    </div>
  )

}
