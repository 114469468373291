import React, { useState, useEffect } from "react";
import { Menu } from '../../Components/Menu';
import api from '../../config/configAPI';
import { useNavigate, Navigate } from 'react-router-dom';

import { PrivacyPolicyModal } from "../../Components/PrivacyPolicyModal";

export const Cadastro_Usuario = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const navigate = useNavigate();
    const [endereco, setEndereco] = useState({
        logradouro: '',
        bairro: '',
        localidade: '',
        uf: ''
    });

    const checkcep = async (e) => {
        if (!e.target.value) return;
        const buscacep = e.target.value.replace(/\D/g, '');
        console.log(buscacep);

        fetch(`https://viacep.com.br/ws/${buscacep}/json`)
            .then(res => res.json())
            .then(data => {
                setEndereco(data);
            })
            .catch((err) => console.log(err));
    };

    const headers = {
        'headers': {
            'Content-Type': 'application/json'
        }
    };

    const [isValid, setIsValid] = useState(false);

    const validateCPF = () => {
        const cpfRegex = /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2})$/;

        if (cpfRegex.test(usuario.Cpf)) {
            const cleanedCPF = usuario.Cpf.replace(/[^\d]/g, '');
            if (/(\d)\1{10}/.test(cleanedCPF)) {
                setIsValid(false);
            } else {
                let sum = 0;
                let remainder;

                for (let i = 1; i <= 9; i++) {
                    sum += parseInt(cleanedCPF.substring(i - 1, i)) * (11 - i);
                }
                remainder = (sum * 10) % 11;
                if (remainder === 10 || remainder === 11) remainder = 0;
                if (remainder !== parseInt(cleanedCPF.substring(9, 10))) {
                    setIsValid(false);
                    return;
                }

                sum = 0;
                for (let i = 1; i <= 10; i++) {
                    sum += parseInt(cleanedCPF.substring(i - 1, i)) * (12 - i);
                }
                remainder = (sum * 10) % 11;
                if (remainder === 10 || remainder === 11) remainder = 0;
                if (remainder !== parseInt(cleanedCPF.substring(10, 11))) {
                    setIsValid(false);
                    return;
                }

                setIsValid(true);
            }
        } else {
            setIsValid(false);
        }
    };

    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    });



    const [usuario, setUsuario] = useState({
        Nome: '',
        Sobrenome: '',
        IdAsaas: '',
        Cpf: '',
        Whatsapp: '',
        Cep: '',
        Endereco: '',
        Numero: '',
        Complemento: '',
        Bairro: '',
        Cidade: '',
        Estado: '',
        Email: '',
        Senha: '',
        Status: 1
    });



    useEffect(() => {
        setUsuario(prevUsuario => ({
            ...prevUsuario,
            Endereco: endereco.logradouro,
            Bairro: endereco.bairro,
            Cidade: endereco.localidade,
            Estado: endereco.uf
        }));
    }, [endereco]);

    useEffect(() => {
        const idAsaas = localStorage.getItem('CdgCliente');
        if (idAsaas) {
            setUsuario(prevUsuario => ({
                ...prevUsuario,
                IdAsaas: idAsaas
            }));
        }
    }, []);

    const valueInput = e => {
        const { name, value } = e.target;
        setUsuario(prevUsuario => ({
            ...prevUsuario,
            [name]: value
        }));
    };

    const [checkEmail, setCheckEmail] = useState(false);


    const Checaremail = async () => {
        try {
            const response = await api.get('/checkEmail/' + usuario.Email);
            if (response.data.exists) {
                setCheckEmail(true);
            } else {
                setCheckEmail(false);
            }

            console.log('Resposta da API ' + response.data.exists)

        } catch (error) {
            console.error('Erro ao verificar e-mail:', error);
        }
    };


    console.log('Email Localizado: ' + checkEmail)



    const addUser = async e => {
        e.preventDefault();
        try {
            const response = await api.post('/cadasaas', {
                name: `${usuario.Nome} ${usuario.Sobrenome}`,
                cpfCnpj: usuario.Cpf,
                email: usuario.Email,
                mobilePhone: usuario.Whatsapp,
                address: usuario.Endereco,
                addressNumber: usuario.Numero,
                complement: usuario.Complemento,
                province: usuario.Bairro,
                postalCode: usuario.Cep,
                cityName: usuario.Cidade,
                state: usuario.Estado
            }, headers);

            console.log(response.data);
            const id = response.data.id;

            setUsuario(prevUsuario => ({
                ...prevUsuario,
                IdAsaas: id
            }));

            localStorage.setItem('CdgCliente', id);
            setStatus({
                type: 'Success',
                mensagem: response.data.mensagem
            });

            localStorage.setItem('Cadastrado', '1');
            await CadInbravisa(id);
        } catch (err) {
            const errorMessage = err.response ? err.response.data.mensagem : 'Erro: Tente Novamente por favor!';
            setStatus({
                type: 'Error',
                mensagem: errorMessage
            });
        }
    };

    const CadInbravisa = async (id) => {
        try {
            const response = await api.post('/cadastrarUser', {
                ...usuario,
                IdAsaas: id
            }, headers);

            setStatus({
                type: 'Success',
                mensagem: response.data.mensagem
            });

            localStorage.setItem('Cadastrado', '1');
            navigate('/Login/');
        } catch (err) {
            const errorMessage = err.response ? err.response.data.mensagem : 'Erro: Tente Novamente por favor!';
            setStatus({
                type: 'Error',
                mensagem: errorMessage
            });
        }
    };
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

    return (
        <div className="w-full h-full bg-slate-400 md:pl-20 md:pr-20 md:pb-40 md:pt-10 pt-10 pl-2 pr-2 pb-20">
            <div className='mb-20'>
                <Menu />
                <br />
            </div>
            <div className="p-3 border bg-slate-300 border-sky-400 rounded-lg mr-10 ml-10 ">
                <p className="text-center text-xl mb-10 pb-5 border-b border-b-slate-950">Por favor, preencha o formulário para realizar seu cadastro</p>
                {status.type === 'Error' && <p className="bg-red-600 text-white text-center text-xl mb-10 pb-5 border-b border-b-slate-950">{status.mensagem}</p>}
                {status.type === 'Success' && <Navigate to="/Login" state={{ type: status.type, mensagem: status.mensagem }} />}

                <form onSubmit={addUser} className="space-y-2">
                    <div className="flex flex-col md:grid md:grid-cols-2 md:gap-5">
                        <div>
                            <label htmlFor="Nome" className="block text-sm font-medium leading-6 text-gray-900">Nome</label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="Nome" placeholder="Digite o seu Nome" onChange={valueInput} required />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="Sobrenome" className="block text-sm font-medium leading-6 text-gray-900">Sobrenome</label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="Sobrenome" placeholder="Digite o seu Sobrenome" onChange={valueInput} required />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col md:grid md:grid-cols-2 md:gap-4">
                        <div>
                            <label htmlFor="Cpf" className="block text-sm font-medium leading-6 text-gray-900">
                                {usuario.Cpf !== '' ? isValid ? (<p style={{ color: 'green' }}>CPF válido</p>) : (<p style={{ color: 'red' }}>CPF inválido</p>) : 'CPF'}
                            </label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="Cpf" placeholder="Digite o seu Cpf" onChange={valueInput} maxLength={11} onBlur={validateCPF} required />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="Whatsapp" className="block text-sm font-medium leading-6 text-gray-900">Whatsapp</label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="Whatsapp" placeholder="(xx) xxxxx-xxxx" maxLength={11} onChange={valueInput} required />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col md:grid md:grid-cols-2 md:gap-5">
                        <div>
                            <label htmlFor="Cep" className="block text-sm font-medium leading-6 text-gray-900">CEP <small>Busca Automática</small></label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="Cep" placeholder="Digite o seu Cep" maxLength={8} onBlur={checkcep} onChange={valueInput} required />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="Endereco" className="block text-sm font-medium leading-6 text-gray-900">Endereço</label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="Endereco" placeholder="Digite o seu Endereço" value={usuario.Endereco} onChange={valueInput} required />
                            </div>
                        </div>

                    </div>
                    <div className="flex flex-col md:grid md:grid-cols-5 md:gap-5">
                        <div>
                            <label htmlFor="Numero" className="block text-sm font-medium leading-6 text-gray-900">Número</label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="Numero" placeholder="Digite o número" onChange={valueInput} required />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="Complemento" className="block text-sm font-medium leading-6 text-gray-900">Complemento</label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="Complemento" placeholder="Digite o complemento" onChange={valueInput} />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="Bairro" className="block text-sm font-medium leading-6 text-gray-900">Bairro</label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="Bairro" placeholder="Digite o seu Bairro" value={usuario.Bairro} onChange={valueInput} required />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="Cidade" className="block text-sm font-medium leading-6 text-gray-900">Cidade</label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="Cidade" placeholder="Digite a sua Cidade" value={usuario.Cidade} onChange={valueInput} required />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="Estado" className="block text-sm font-medium leading-6 text-gray-900">Estado</label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="Estado" placeholder="Digite o seu Estado" value={usuario.Estado} onChange={valueInput} required />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col md:grid md:grid-cols-2 md:gap-5">
                        <div>
                            <label htmlFor="Email" className="block text-sm font-medium leading-6 text-gray-900">E-mail</label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="email" name="Email" placeholder="Digite o seu E-mail" onChange={valueInput} onBlur={Checaremail} required />
                                {checkEmail === true && <p className="bg-red-500 text-white p-2">Este e-mail já está cadastrado.</p>}
                                
                            </div>

                        </div>
                        <div>
                            <label htmlFor="Senha" className="block text-sm font-medium leading-6 text-gray-900">Senha</label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="password" name="Senha" placeholder="Digite a sua senha" onChange={valueInput} required />

                            </div>
                        </div>
                    </div>
                    <div className="flex items-center md:grid md:grid-cols-1 md:gap-5">
                        <div className="flex items-center">
                            <label for="privacyPolicy">Ao se cadastrar, você concorda com a nossa política de privacidade</label>
                            <input
                                id="privacyPolicy"
                                type="checkbox"
                                className="h-4 w-4 ml-2 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
                                required
                            />
                            <button
                                onClick={handleOpenModal}
                                className="bg-indigo-500 hover:bg-indigo-700 text-white px-2 py-1 rounded ml-2"
                            >
                                Política de Privacidade
                            </button>
                            <br></br>
                            <PrivacyPolicyModal isOpen={isModalOpen} onClose={handleCloseModal} />
                        </div>
                    </div>

                    <div className="flex justify-center">
                        {isValid === true && checkEmail === false ? (<div><button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Cadastrar</button></div>) : (<div><span className=" bg-yellow-500 text-slate-900">Por favor preencha as informações corretamente para realizar o cadastro</span></div>)}

                    </div>
                </form>
            </div>
        </div>
    );
};