import React, { useState, useEffect } from "react";
import { Menu } from '../../Components/Menu';
import { Link, useParams } from "react-router-dom";
import api from "../../config/configAPI";
import { Rodape } from "../../Components/Rodape";
import { FaShoppingCart, FaArrowLeft } from 'react-icons/fa';






export const Layout = () => {


    const token = localStorage.getItem('token');

    const { id } = useParams();

    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    })

    const [data, setData] = useState([
    ]);


    useEffect(() => {
        const getUser = async () => {

            await api.get("/Protocolo/" + id,)
                .then((response) => {
                    if (response.data.user) {
                        setData(response.data.user)
                    } else {
                        setStatus({
                            type: "error",
                            mensagem: "Erro. Usuário não encontrado"

                        })
                    }

                }).catch((err) => {
                    if (err.response) {
                        setStatus({
                            type: "error",
                            mensagem: err.response.data.mensagem
                        })
                    } else {
                        setStatus({
                            type: "error",
                            mensagem: "Erro. Tente mais tarde"
                        })
                    }
                })
        }
        getUser()
    }, [id])

    const mensagemAdd = {
        type: status.type,
        mensagem: status.mensagem
    }

    return (

        <div>
            <Menu />

            <div className="flex mt-10 bg-slate-200  xl:w-3/4 lg:w-2/3 md:w-2/3 mb-36 rounded-lg mx-auto my-auto">
      <div className="row-span-3 p-5">
        <div>
          <h1 className="sm:text-left text-3xl mb-5">
            <strong> {data.Nome}</strong>
          </h1>
        </div>
        <div>
          <img className="float-left w-56 h-56 mr-5" src={data.LinkImagem} alt={data.Nome} />
          <p>
            <strong>Descrição: </strong> {data.Descricao}
          </p>
          <br />
          <p>
            <strong>Conteúdo: </strong> {data.Conteudo}
          </p>
          <br />
        </div>
      </div>
    </div>

           

            <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 justify-end flex flex-row space-x-4">         
                
                {
                    data.Promocao != 1 ?
                        <div className="text-center mb-5">
                            <p>R${data.Valor},00</p>
                        </div>
                        :
                        <div className="text-center mb-5 flex flex-row space-x-4">
                            <p>PROMOÇÃO</p>
                            <p className="bg-red-500 line-through text-white ">R${data.Valor},00</p>
                            <p className="bg-emerald-800 text-white p-1">R${data.Valor_Desconto},00</p>
                        </div>
                }
                   
                    {
                        token ?
                            <Link to={"/Checkout/" + data.id} >
                                <button className="flex items-center space-x-2 bg-emerald-600 hover:bg-emerald-800 text-white px-10 sm:px-5 md:px-5 py-2 rounded-md">
                                    <FaShoppingCart size={20} />
                                    <span>Comprar</span>
                                </button>
                            </Link>
                            :
                            <Link to={"/Login/" + data.id} >
                                <button className="flex items-center space-x-2 bg-emerald-600 hover:bg-emerald-800 text-white px-10 py-2 rounded-md">
                                    <FaShoppingCart size={20} />
                                    <span>Comprar</span>
                                </button>
                            </Link>
                    }                   
                </div>
                
            </div>



        
    )
}

