import React, { useEffect, useState } from "react";
import api from "../../config/configAPI";
import { Link, useParams } from "react-router-dom";

export const Assistir = () => {
  const { idaula, NomeAula, idProtocolo } = useParams();
  const [aulas, setAulas] = useState([]);

  const contador = {
    idCadastro: localStorage.getItem("idUser"),
    NomeCadastro: localStorage.getItem("Nome"),
    idAula: idaula,
    NomeAula: NomeAula,
  };

  // Função para carregar a aula
  const Aula = async () => {
    try {
      const response = await api.get(`/VisualizarAula/${idaula}`);
      setAulas(response.data.aula);
    } catch (err) {
      console.error(
        err.response?.data?.mensagem || "Erro: Tente novamente mais tarde."
      );
    }
  };

  // Função para registrar aula como assistida
  const AulaAssistida = async () => {
    try {
      await api.post("/cadAssistidas", contador);
    } catch (err) {
      console.error(
        err.response?.data?.mensagem || "Erro: Tente novamente mais tarde."
      );
    }
  };

  useEffect(() => {
    AulaAssistida();
    Aula();
  }, []);

  const videoURL = aulas.LinkAula;

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center py-10 px-4">
      {/* Cabeçalho */}
      <div className="bg-white shadow-lg rounded-lg w-full max-w-4xl p-6 text-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">{NomeAula}</h1>
      </div>

      {/* Vídeo */}
      <div className="bg-white shadow-md rounded-lg w-full max-w-4xl flex justify-center p-6">
        {videoURL ? (
          <iframe
            className="rounded-lg border border-gray-300"
            src={videoURL}
            title={`Assistindo ${NomeAula}`}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
            allowFullScreen
            width="100%"
            height="480"
          />
        ) : (
          <p className="text-gray-500">O vídeo não está disponível.</p>
        )}
      </div>

      {/* Botões de ação */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6 w-full max-w-4xl">
        <button className="bg-blue-700 hover:bg-blue-900 text-white font-bold py-3 rounded-lg shadow transition flex justify-center items-center w-full h-16">
          📥 Baixar Material
        </button>
        <button className="bg-green-700 hover:bg-green-900 text-white font-bold py-3 rounded-lg shadow transition flex justify-center items-center w-full h-16">
          ⭐ Avaliar Aula
        </button>
        <Link to={`/Listar_Aulas/${idProtocolo}`}>
          <button className="bg-gray-700 hover:bg-gray-900 text-white font-bold py-3 rounded-lg shadow transition flex justify-center items-center w-full h-16">
            🔙 Voltar
          </button>
        </Link>
      </div>
    </div>
  );
};
