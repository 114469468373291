import React, { useState, useEffect } from 'react';
import api from '../../config/configAPI';

export const BuscaStatusPagamento = ({ idCompra, onStatusUpdate }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [edit, setEdit] = useState('');
    const headers = {
        'Content-Type': 'application/json'
    };

    const handleVerificarPagamento = async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`/StatusCompra?id=${idCompra}`, { headers });
            console.log('Acessou a API', response.data.status);
            onStatusUpdate(idCompra, response.data.status);

            const responsestatus = await api.get(`/VerificaCompra?id=${idCompra}`, { headers });
            console.log('Verificou se a compra existe e o status é: ' + responsestatus.data.compra.Status);



            if (response.data.status !== responsestatus.data.compra.Status) {
                console.log('Precisa atualizar')
                const updateResponse = await api.put('/editarPagamento', { idCompra, status: response.data.status }, { headers });
                setEdit(updateResponse.data.mensagem);
                console.log(updateResponse.data.mensagem);
            } else {
                console.log("Não precisa atualizar")
            }

        } catch (error) {
            console.error("Erro ao buscar status da compra:", error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        handleVerificarPagamento();
    }, []);

    return (
        <button
            onClick={handleVerificarPagamento}
            className={`bg-sky-500 hover:bg-sky-300 text-white text-xl pl-5 pr-5 border rounded-lg items-center align-middle pb-2 pt-2 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isLoading}
        >
            {isLoading ? 'Verificando...' : 'Verificar Pagamento'}
        </button>
    );
};


