import React, { useEffect, useState } from "react";
import api from "../../config/configAPI";
import { Link } from 'react-router-dom';
import { BuscaStatusPagamento } from '../BuscaStatusPagamento'; // Certifique-se de ajustar o caminho conforme necessário

export const CardCompras = ({ Nome, Valor, Descricao }) => {
    const token = localStorage.getItem('token');
    const idCadastro = localStorage.getItem('idUser');

    const [data, setData] = useState([]);
    const [statuspg, setStatuspg] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [server, setServer] = useState({
        type: '',
        mensagem: ''
    });

    const getUsers = async () => {
        try {
            const response = await api.get(`/Compras/${idCadastro}`);
            console.log('Acessou o BD de compras');
            setData(response.data.users);
            setIsLoading(false);
        } catch (error) {
            console.error("Erro ao obter status das compras:", error);
            setServer({
                type: 'Erro',
                mensagem: error.response?.data?.mensagem || 'Erro ao obter status das compras'
            });
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    console.log('subiu certo')

    const handleStatusUpdate = (idCompra, status) => {
        setStatuspg(prevStatuspg => ({
            ...prevStatuspg,
            [idCompra]: status
        }));
    };

    if (isLoading) {
        return <div>Carregando...</div>;
    }

    if (server.type === 'Erro') {
        return <div>{server.mensagem}</div>;
    }

    return (
        <div className="flex flex-wrap justify-center">
            {data.map(user => (
                <div className="p-2 m-5 w-96 h-auto border 2px shadow-lg hover:bg-slate-100 hover:text-black duration-300" key={user.id}>
                    <div>
                        <p className="text-center text-xl font-bold">{user.NomeProtocolo}</p>
                    </div>
                    <div>
                        <img className="object-cover h-48 w-96" src={user.ImagemProtocolo} alt="Protocolo" />
                    </div>
                    <div>
                    </div>
                    <div className="flex justify-center mt-16 mb-2">

                    
                        <div className="flex">
                            {statuspg[user.idcompra] === 'RECEIVED' || statuspg[user.idcompra] === 'CONFIRMED' ? (
                                <div>
                                    <Link className="bg-emerald-500 hover:bg-emerald-400 text-white text-xl pl-5 pr-5 border rounded-lg items-center align-middle pb-2 pt-2" to={`/Listar_Aulas/${user.idProtocolo}`}>
                                        <button type="button">Acessar Protocolo</button>
                                    </Link>
                                </div>
                            ) : (
                                <div>
                                    <p className="bg-yellow-500 text-white text-xl pl-5 pr-5 border rounded-lg items-center align-middle pb-2">
                                        Aguardando Pagamento...
                                    </p>
                                    <br />
                                    {user.Tipo === "BOLETO"?(<div><span>O pagamento por boleto bancário pode demorar até 3 dias úteis para compensação</span></div>):''}
                                    
                                    <BuscaStatusPagamento idCompra={user.idcompra} onStatusUpdate={handleStatusUpdate} />
                                    
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};