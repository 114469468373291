import React, { useContext, useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import { Context } from '../../Context/authContext';

export const Menu = () => {
  const { handleLogout } = useContext(Context);
  const [isOpen, setIsOpen] = useState(false);
  const [isTransparent, setIsTransparent] = useState(true);

  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleScroll = () => {
      setIsTransparent(window.scrollY === 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      {/* Top Navigation */}
      <nav
        className={`fixed top-0 left-0 w-full flex items-center justify-between py-4 px-6 z-50 transition-all duration-300 ${
          isTransparent ? 'bg-gradient-to-r from-emerald-500 via-emerald-600 to-emerald-700 shadow-md' : 'bg-emerald-800 shadow-lg'
        }`}
      >
        {/* Title */}
        <div className="flex-1 flex items-center justify-center">
          <h1 className="text-lg sm:text-xl font-bold text-white">
            INBRAVISA - Olá {localStorage.getItem('Nome')}
          </h1>
        </div>

        {/* Menu Button */}
        <div className="flex items-center">
          <button
            onClick={toggleMenu}
            className="p-3 text-white rounded-full hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-400"
            aria-label="Abrir menu lateral"
          >
            <h1>⚙️ Configurações </h1>
          </button>
        </div>
      </nav>

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 right-0 bg-gray-900 w-64 text-white transition-transform duration-500 transform ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        } shadow-lg`}
      >
        <ul className="py-6 px-4 space-y-6">
          <li>
            <Link to="/Area_Aluno" onClick={toggleMenu}>
              <div className="flex items-center space-x-2 hover:bg-emerald-800 p-3 rounded-lg transition-colors mt-20">
                <span className="text-white">🏠</span>
                <MenuItem className="text-white">Painel dos Cursos</MenuItem>
              </div>
            </Link>
          </li>
          <li>
            <Link to="" onClick={toggleMenu}>
              <div className="flex items-center space-x-2 hover:bg-emerald-800 p-3 rounded-lg transition-colors">
                <span className="text-white"> 👤</span>
                <MenuItem className="text-white">Usuário</MenuItem>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/Cursos" onClick={toggleMenu}>
              <div className="flex items-center space-x-2 hover:bg-emerald-800 p-3 rounded-lg transition-colors">
                <span className="text-white">🛒</span>
                <MenuItem className="text-white">Comprar Protocolos</MenuItem>
              </div>
            </Link>
          </li>
          <li>
            <button
              type="button"
              onClick={handleLogout}
              className="w-full text-left flex items-center space-x-2 bg-red-500 hover:bg-red-700 px-4 py-3 rounded-lg text-white transition-colors duration-300"
            >
              <span>🚪</span>
              <span>Sair</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};









/*
import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import Menus from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import { Context } from '../../Context/authContext';



import{Area_Aluno} from '../../Pages/Area_Aluno';


export const Menu = () => {

  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  const { handleLogout, authenticated } = useContext(Context);

  console.log('O token é:' + token);




  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="bg-sky-700 p-5 flex justify-between text-white">
      <Button
        color="primary"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='contained'
      >
        <h1>   <FiMenu />   Menu</h1>
      </Button>
      <Menus
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Link to={'/'}><MenuItem onClick={handleClose}>Home</MenuItem></Link>
        <Link to={'/Area_Aluno'}><MenuItem onClick={handleClose}>Meus Protocolos</MenuItem></Link>
        <Link to={'/'}><MenuItem onClick={handleClose}>Certificado</MenuItem></Link>
        <Link to={'/'}><MenuItem onClick={handleClose}>Meus Dados</MenuItem></Link>
      </Menus>

      <div>
        <p className='ml-5 text-3xl'>INBRAVISA - Olá {localStorage.getItem('Nome')} </p>
      </div>

      <div>

      <Link to="/"><button type='button' onClick={handleLogout} className=' bg-red-500 hover:bg-red-800 p-2 rounded-lg border-white'>Sair</button> </Link>

      </div>

    </div>
  );
}

*/
