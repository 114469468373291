import React, { useState } from "react";
import { Menu } from '../../Components/Menu';




export const RecSenha = () => {

const [enviado, setEnviado] = useState({
    'type':'',
    'mensagem':''
});



    return (

        <div>
            <div className='mb-20'>
               <Menu /> 
               <br />
            </div>
            <br/>
                <br/>

            <div className="p-5">
            
                Por favor, preencha o campo abaixo com o email cadastro, para iniciar a etapa de recuperação de senha:
                <hr />
                <br/>
                <br/>
                
                <form className="space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Por favor digite o seu email cadastrado
                        </label>
                        <div className="mt-2">
                            <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6 p-2" type="text" name="Email" placeholder="Digite o seu email" />
                        </div>
                    </div>
                    <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Recuperar Senha
                            </button>
                </form>
            </div>
            



        </div>
    )
}