import React, { useState, useContext } from "react";
import { Context } from '../../Context/authContext';
import api from '../../config/configAPI';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { Menu } from "../../Components/Menu";
import { Rodape } from "../../Components/Rodape";

export const Login = () => {
    const { produto } = useParams();
    const navigate = useNavigate();
    const { authenticated, signIn } = useContext(Context);
    const [user, setUser] = useState({ Email: '', Senha: '' });
    const [status, setStatus] = useState({ type: '', mensagem: '', loading: false });

    const valorinput = (e) => setUser({ ...user, [e.target.name]: e.target.value });

    const loginSubmit = async (e) => {
        e.preventDefault();
        setStatus({ loading: true });
        const headers = { 'Content-Type': 'application/json' };
        await api.post("/realizarlogin", user, { headers })
            .then((response) => {
                setStatus({ type: 'Sucesso', mensagem: response.data.mensagem, loading: false });
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('idUser', response.data.id);
                localStorage.setItem('Nome', response.data.Nome);
                localStorage.setItem('Id Asaas', response.data.IdAsaas);
                signIn(true);
                produto >= 1 ? navigate('/Checkout/' + produto) : navigate('/Area_Aluno/');
            })
            .catch((err) => {
                if (err.response) {
                    setStatus({ type: 'error', mensagem: err.response.data.mensagem, loading: false });
                } else {
                    setStatus({ type: 'error', mensagem: 'Erro, tente mais tarde!', loading: false });
                }
            });
    };

    return (        
    <>
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8"> 
        <Menu />
        
        <div className="sm:mx-auto sm:w-full lg:max-w-md">
            {/* Container principal para o formulário de login */}
            <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10 w-full">
                <img
                    className="w-36 h-36 mx-auto"
                    src="http://www.inbravisa.com.br/imagens/logo%20INBRAVISA_FB%20(13).jpg"
                    alt="Logo Inbravisa"
                /> 
                <h2 className="text-center text-3xl font-extrabold text-gray-900">
                    {produto >= 1 ? "Faça login para continuar sua compra" : "Entre na sua conta"}
                </h2>
                
                <p className="mt-2 text-center text-sm text-gray-600">
                    Não tem uma conta?{' '}
                    <Link to={'/Cadastro_Usuario'} className="bg-indigo-600 font-medium text-white hover:text-indigo-100 rounded-md p-1 hover:bg-indigo-900">
                        Cadastre-se agora
                    </Link>
                </p>

                {status.type === 'error' && <p className="bg-red-500 text-white text-center p-2 mb-4 rounded">{status.mensagem}</p>}
                {status.type === 'Sucesso' && <p className="bg-green-500 text-white text-center p-2 mb-4 rounded">{status.mensagem}</p>}
                {status.loading && <p className="text-center text-gray-600">Verificando Login...</p>}

                <form className="space-y-6" onSubmit={loginSubmit}>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Usuário
                        </label>
                        <div className="mt-1">
                            <input
                                name="Email"
                                type="email"
                                onChange={valorinput}
                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Digite seu email"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Senha
                        </label>
                        <div className="mt-1">
                            <input
                                name="Senha"
                                type="password"
                                onChange={valorinput}
                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Digite sua senha"
                            />
                        </div>
                        <div className="flex justify-end mt-2">
                            <Link to={"/Rec_senha"} className="text-sm text-indigo-600 hover:text-indigo-500">
                                Esqueceu a senha?
                            </Link>
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Entrar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div>
        <Rodape />
    </div>

</>
    );
};