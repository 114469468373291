import React, { useState } from 'react';

export const CopiarTexto = ({ texto }) => {
    const [copiado, setCopiado] = useState(false);

    const copiarTexto = () => {
        navigator.clipboard.writeText(texto)
            .then(() => {
                setCopiado(true);
                setTimeout(() => setCopiado(false), 3000);
            })
            .catch(err => console.error('Erro ao copiar texto: ', err));
    };

    return (
        <div className="w-full md:w-1/2 justify-center">
            <h2 className="text-lg font-bold mb-4">Pix Copia e Cola</h2>

            <div className="flex mt-8">
                <div className="break-words w-full">
                    {texto}
                </div>

            </div> <br />
            <div className="flex flex-row items-center justify-center">
                <div>
                    <button
                        className="bg-sky-500 p-5 items-center text-white rounded-lg w-96"
                        onClick={copiarTexto}
                    >
                        Copiar
                    </button>
                </div>
               <p> {copiado && <p className="text-green-500">Texto copiado!</p> }</p>
            </div>

        </div>
                           
    
  );
};

