import React, { useState, useEffect } from "react";
import api from "../../config/configAPI";
import { Rodape } from "../../Components/Rodape";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { Menu } from "../../Components/Menu"
import { Menu as MenuAluno } from "../../Components/Menu_Aluno"
import PaymentOption from "../../Components/PaymentOption";
import PagamentoEnvioCartao from "../../Components/PagamentoEnvioCartao";

//Componentes
import { BoletoPayment } from "../../Components/BoletoPayment";

//icones
import { FaPix, FaBarcode, FaRegCreditCard } from "react-icons/fa6";
import { TbLockCheck } from "react-icons/tb";



export const Checkout = () => {



  const navigate = useNavigate();

  const [creditCardNumber, setCreditCardNumber] = useState("");

  const handleCreditCardChange = (value) => {
    setCreditCardNumber(value);
  };

  const [selectedPayment, setSelectedPayment] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    cpf: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    installmentCount: "",
  });


  const handleSelectPayment = (payment) => {
    setSelectedPayment(payment);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const token = localStorage.getItem('token');
  const { idProduto } = useParams();

  localStorage.setItem('idaula', idProduto)

  const [desconto, setDesconto] = useState({
    txtCupom: ''
  });

  const [] = useState({
    nomeCupom: '',
    valorDesconto: ''
  })

  const [status, setStatus] = useState({
    type: '',
    mensagem: ''
  })

  const [statuscupom, setStatuscupom] = useState({
    type: '',
    mensagem: '',
    nome: '',
    desconto: ''
  })

  const [data, setData] = useState([
  ]);

  const [statusMsg, setStatusMsg] = useState({
    type: '',
    mensagem: ''
  })

  // Obtém a data atual
  const dataAtual = new Date();

  // Adiciona um dia à data atual
  const umDiaDepois = new Date(dataAtual);
  umDiaDepois.setDate(umDiaDepois.getDate() + 5);


  // Formata a data para exibição
  const dia = String(umDiaDepois.getDate()).padStart(2, '0');
  const mes = String(umDiaDepois.getMonth() + 1).padStart(2, '0');
  const ano = umDiaDepois.getFullYear();

  const dataFormatada = `${ano}-${mes}-${dia}`;

  const headers = {
    'headers': {
      'Content-Type': 'application/json'
    }
  }

  useEffect(() => {
    const getUser = async () => {
      await api.get("/Protocolo/" + idProduto,)
        .then((response) => {
          if (response.data.user) {
            setData(response.data.user)
            //console.log(response.data.user)
            localStorage.setItem('NomeAula', response.data.user.Nome)
            localStorage.setItem('Img', response.data.user.LinkImagem)
          } else {
            setStatusMsg({
              type: "error",
              mensagem: "Erro. Usuário não encontrado"

            })
          }

        }).catch((err) => {
          if (err.response) {
            setStatusMsg({
              type: "error",
              mensagem: err.response.data.mensagem
            })
          } else {
            setStatusMsg({
              type: "error",
              mensagem: "Erro. Tente mais tarde"
            })
          }
        })
    }
    getUser()
  }, [])

  const [produto, setProduto] = useState({
    "title": data.Nome,
    "unit_price": data.Promocao !== 1 ? data.Valor : data.Valor_Desconto,
    "quantity": 1
  });

  //console.log(produto)




  const [valorPronto, setValorPronto] = useState(0);
  const [cupomAplicado, setCupomAplicado] = useState(0);
  const DescValor = valorPronto - (valorPronto * 0.05)
  const valueCupom = e => setDesconto({ ...desconto, [e.target.name]: e.target.value });


  const ativarCupom = async e => {
    e.preventDefault();
    console.log(desconto);

    await api.get('/buscaCupom/' + desconto.txtCupom)
      .then((res) => {
        //console.log(res.data);
        //console.log(res.data.cupons.Nome);
        //console.log(res.data.cupons.Desconto);
        //console.log(status)
        //localStorage.setItem('Cadastrado','1');
        setStatuscupom({
          type: 'Ok',
          mensagem: 'Cupom Aplicado',
          nome: res.data.cupons.Nome,
          desconto: res.data.cupons.Desconto
        });
        localStorage.setItem('Cupom', res.data.cupons.Nome)
        localStorage.setItem('Desconto', res.data.cupons.Desconto)
        localStorage.setItem('idCupom', res.data.cupons.id)

        const valorAplicado = '0.' + res.data.cupons.Desconto

        const precoCupom = valorPronto - (valorPronto * valorAplicado)

        //console.log('O valor do Produto é: ' + precoCupom)
        setCupomAplicado(precoCupom)

      }).catch((err) => {
        if (err.response) {
          //console.log(err.response.data.mensagem);
          setStatuscupom({
            type: 'Error',
            mensagem: 'Cupom não encontrado'
          });
        } else {
          //console.log('Erro: Tente Novamente por favor! ');
          setStatuscupom({
            type: 'Error',
            mensagem: 'Erro: Tente Novamente por favor! '
          });

        }
      })

  }

  const alterarValor = () => {

    data.Promocao == 1 ?
      setValorPronto(data.Valor_Desconto) :
      setValorPronto(data.Valor)



    cupomAplicado > 1 ?

      setValorPronto(cupomAplicado) :

      //console.log('O valor do Cupom aplicado é: ' + cupomAplicado)

      //console.log('dentro da Função o valor final é: ' + valorPronto)

      localStorage.setItem('ValorCompra', valorPronto)



  }

  useEffect(() => {
    alterarValor();
  })


  const transacaoPix = ({
    "idCadastro": localStorage.getItem('idUser'),
    "idcompra": localStorage.getItem(''),
    "idAsaas": localStorage.getItem('Id Asaas'),
    "Nome": localStorage.getItem('Nome'),
    "idProtocolo": localStorage.getItem('idaula'),
    "Valor": DescValor.toFixed(2),
    "IdCupom": localStorage.getItem('idCupom'),
    "Nomecupom": localStorage.getItem('Cupom'),
    "NomeProtocolo": localStorage.getItem('NomeAula'),
    "ImagemProtocolo": localStorage.getItem('Img'),
    "Expiracao": umDiaDepois
  })







  // Número total de parcelas
  const totalParcelas = 10;

  // Valor total da compra em reais
  const valorTotal = valorPronto;

  // Calcula o valor de cada parcela
  const valorParcela = (valorTotal / totalParcelas).toFixed(2);

  // Gera as opções de parcelamento
  const options = [];
  for (let i = 1; i <= totalParcelas; i++) {
    const parcelamento = parseFloat(valorTotal / i).toFixed(2);
    const valorParceladoFormatado = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(parcelamento);
    options.push(<option key={i} name="installmentCount" value={i}>{i}x {valorParceladoFormatado}</option>);
  }


  const PagamentoPix = async e => {
    localStorage.removeItem('IdCobranca');
    localStorage.removeItem('ValorCobrado');
    localStorage.removeItem('TipoCobranca');
    localStorage.removeItem('StatusCobranca');
    e.preventDefault();
    await api.post('/PagamentoPix', transacaoPix, headers)

      .then((res) => {
        console.log(res);
        //console.log("o Id da cobrança é: " + res.data.id)
        localStorage.setItem('IdCobranca', res.data.id);
        localStorage.setItem('ValorCobrado', res.data.value);
        localStorage.setItem('TipoCobranca', res.data.billingType);
        localStorage.setItem('StatusCobranca', res.data.status);
        cadPagamento();

      })
    //console.log(transacao)

    return navigate('/Pagamento/')

  }

  const cadPagamento = async () => {
    const dadoscompra = {
      idCadastro: localStorage.getItem('idUser'),
      idcompra: localStorage.getItem('IdCobranca'),
      idAsaas: localStorage.getItem('Id Asaas'),
      Nome: localStorage.getItem('Nome'),
      idProtocolo: localStorage.getItem('idaula'),
      NomeProtocolo: localStorage.getItem('NomeAula'),
      ImagemProtocolo: localStorage.getItem('Img'),
      idCupom: localStorage.getItem('idCupom'),
      Nomecupom: localStorage.getItem('Cupom'),
      Status: localStorage.getItem('StatusCobranca'),
      Valor: localStorage.getItem('ValorCobrado'),
      Tipo: localStorage.getItem('TipoCobranca')
    };

    try {
      const response = await api.post('/CadComprar', dadoscompra, headers);
      setStatus({
        type: 'Success',
        mensagem: response.data.mensagem
      });
      localStorage.setItem('Cadastrado', '1');
      navigate('/Pagamento/');
    } catch (err) {
      if (err.response) {
        setStatus({
          type: 'Error',
          mensagem: err.response.data.mensagem
        });
      } else {
        setStatus({
          type: 'Error',
          mensagem: 'Erro: Tente Novamente por favor!'
        });
      }
    }
  };






  return (
    <div>
      <div className="container mx-auto mt-8">
        <div className="mt-28">
          {token === '' ? <MenuAluno /> : <Menu />}
        </div>

        <p className="flex items-center m-5 text-3xl font-bold mb-8 text-green-700">
          Checkout <TbLockCheck className="text-green-700 ml-1 mr-5" />
          <small>  Nosso site utiliza conexão SSL e nenhuma informação de pagamento é armazenada em nosso sistema</small>
        </p>

        <div className="border border-blue-400 p-2">
          <div className="w-full md:flex md:space-x-8 md:p-0">
            <div className="w-full md:w-1/2  md:border-r border-blue-400 md:p-5">
              <h2 className="text-lg font-bold mb-4">Detalhes do Pedido</h2>
              <div className="border p-4 mb-8">
                <div><p className="text-center text-2xl">{data.Nome}</p></div>
                <div className="mt-8">
                  {data.Promocao !== 1 ?
                    <p className=" bg-emerald-600 text-white p-2 pl-5">Valor do Curso - R${valorPronto},00</p>
                    :
                    <div className=" flex justify-center bg-emerald-600 text-white p-2 pl-5">
                      <p className="line-through text-red-600 mr-5">De R${data.Valor},00</p>

                      <p>Por: R${valorPronto},00 </p>
                    </div>
                  }
                  <br />
                </div>
                <div className="bg-slate-300 w-full items-center rounded-md p-5">
                  {statuscupom.type !== 'Ok' ?
                    <div>
                      <p className="text-center">Possuí Cupom de indicação?</p>
                      <form onSubmit={ativarCupom}>
                        <input className="mt-5 mb-5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="txtCupom" onChange={valueCupom} placeholder="Digite o Cupom" />
                        <button type='submit' className="w-full hover:bg-sky-800 bg-sky-500 border broder-1 rounded-lg text-white p-2">Usar Cupom</button>
                      </form>
                    </div>
                    :
                    <div className="bg-emerald-500 rounded-md text-white p-2 text-center">Cupom Cadastrado com Sucesso <br /></div>
                  }
                  {statuscupom.type === 'Error' ? <div className="bg-red-400 p-2">Cupom não localizado <br /></div> : ''}
                </div>
                <br />
                <div><img className="w-auto h-auto" src={data.LinkImagem} alt={data.Nome} /></div>
              </div>
            </div>

            <div className="w-full md:w-1/2">
              <h2 className="text-lg font-bold mb-4">Opções de Pagamento</h2>
              <div className="space-y-4 text-3xl font-bold font">
                <PaymentOption label="Pix - 5% de Desconto" icon={FaPix} onSelect={handleSelectPayment} />
                <PaymentOption label="Boleto Bancário" icon={FaBarcode} onSelect={handleSelectPayment} />
                <PaymentOption label="Cartão de Crédito" icon={FaRegCreditCard} onSelect={handleSelectPayment} />
              </div>

              {selectedPayment === "Pix" && (
                <div className="mt-4">
                  <h3 className="text-lg font-bold mb-4">Informações para pagamento por pix</h3>
                  <div className="grid grid-cols-2 gap-4">
                  </div>
                </div>
              )}

              {selectedPayment === "Boleto Bancário" && (
                <div>Boleto Bancário</div>
              )}

              {selectedPayment === "Cartão de Crédito" && (
                <div className="w-full">
                  <PagamentoEnvioCartao valorTotal={valorPronto} vencimento={dataFormatada} iduser={localStorage.getItem('Id Asaas')} />
                </div>

              )}


              <div className="mt-8">
                {selectedPayment === "Pix - 5% de Desconto" && (
                  <div>
                    {data.Promocao === 1 && selectedPayment !== "Pix - 5% de Desconto" ? (
                      <p className="border border-green-800 text-green-800 p-2 pl-5">Valor do Curso - R${valorPronto}</p>
                    ) : (
                      <div className="flex justify-center border border-green-800 text-green-800 p-2 pl-5">
                        <p className="line-through text-red-600 mr-5">De R${data.Valor},00</p>
                        <p>Por: R${DescValor.toFixed(2)}</p>
                      </div>
                    )} <br />
                    <br />
                    <button
                      className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded items-center w-full"
                      onClick={PagamentoPix}
                    >
                      Prosseguir para Pagar com PIX
                    </button>



                  </div>
                )}
                {selectedPayment === "Boleto Bancário" && (
                  <BoletoPayment transacaoPix={transacaoPix} />
                )}

              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

      </div>
      <div class="relative mt-10">
        <Rodape />

      </div>
    </div>
  );
};
