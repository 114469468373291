import React from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../config/configAPI';

export const BoletoPayment = ({ transacaoPix }) => {
  const navigate = useNavigate();

  const headers = {
    'headers': {
      'Content-Type': 'application/json'
    }
  };

  const PagamentoBoletos = async e => {
    e.preventDefault();
    localStorage.removeItem('IdCobranca');
    localStorage.removeItem('ValorCobrado');
    localStorage.removeItem('TipoCobranca');
    localStorage.removeItem('StatusCobranca');
    
    try {
      const res = await api.post('/PagamentosBoleto', transacaoPix, headers);
      console.log(res);
      localStorage.setItem('IdCobranca', res.data.id);
      localStorage.setItem('ValorCobrado', res.data.value);
      localStorage.setItem('TipoCobranca', res.data.billingType);
      localStorage.setItem('StatusCobranca', res.data.status);
      localStorage.setItem('urlBoleto', res.data.bankSlipUrl)
      //await cadPagamento();
      navigate('/PagamentoBoleto/');
    } catch (err) {
      console.error("Erro ao realizar pagamento: ", err);
    }
  };
/*
  const cadPagamento = async () => {
    const dadoscompra = {
      idCadastro: localStorage.getItem('idUser'),
      idcompra: localStorage.getItem('IdCobranca'),
      idAsaas: localStorage.getItem('Id Asaas'),
      Nome: localStorage.getItem('Nome'),
      idProtocolo: localStorage.getItem('idaula'),
      NomeProtocolo: localStorage.getItem('NomeAula'),
      ImagemProtocolo: localStorage.getItem('Img'),
      idCupom: localStorage.getItem('idCupom'),
      Nomecupom: localStorage.getItem('Cupom'),
      Status: localStorage.getItem('StatusCobranca'),
      Valor: localStorage.getItem('ValorCobrado'),
      Tipo: localStorage.getItem('TipoCobranca')
    };
    
    try {
      const response = await api.post('/CadComprar', dadoscompra, headers);
      console.log("Cadastro de compra realizado: ", response.data.mensagem);
    } catch (err) {
      console.error("Erro ao cadastrar compra: ", err);
    }
  };
  */

  return (
    <div>
      <h3 className="text-lg font-bold mb-4">Informações para pagamento por Boleto Bancário</h3>
      <button className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded items-center w-full" onClick={PagamentoBoletos}>
        Prosseguir para Pagamento
      </button>
    </div>
  );
};

