import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { FaLock, FaSpinner } from 'react-icons/fa';
import { Payment } from '@mercadopago/sdk-react';
import { initMercadoPago } from '@mercadopago/sdk-react'
import api from '../../config/configAPI';



// Produção 
initMercadoPago('APP_USR-ed598bc3-0079-4b96-a43d-487af04e9a41');
//71044586346

//import Modal from "../../Components/Modal";

Modal.setAppElement('#root');

export const Pagamento = () => {
  
  
  
  
  const navigate = useNavigate();

  // Hook que demonstra se a modal está aberta ou não
  const [modalIsOpen, setIsOpen] = React.useState(false);


  // Função que abre a modal
  function abrirModal() {
    setIsOpen(true);
  }

  // Função que fecha a modal
  function fecharModal() {
    setIsOpen(false);
  }


  const token = localStorage.getItem('token');

  const { idProduto } = useParams();

  const [loading, setLoading] = useState(true)

  const [statusCompra, setStatusCompra] = useState();

  const [compra, setCompra] = useState();

  const [cupom, setCupom] = useState({
    "txtCupom": '',
  });

  const [status, setStatus] = useState({
    type: '',
    mensagem: ''
  })

  const [data, setData] = useState([
  ]);





  useEffect(() => {
    const getUser = async () => {
      await api.get("/ComprarItem/" + idProduto,)
        .then((response) => {
          if (response.data.user) {
            setData(response.data.user)
          } else {
            setStatus({
              type: "error",
              mensagem: "Erro. Usuário não encontrado"
            })
          }
        }).catch((err) => {
          if (err.response) {
            setStatus({
              type: "error",
              mensagem: err.response.data.mensagem
            })
          } else {
            setStatus({
              type: "error",
              mensagem: "Erro. Tente mais tarde"
            })
          }
        })
    }
    getUser()
  }, [idProduto])

  const [produto, setProduto] = useState({
    "title": data.Nome,
    "unit_price": localStorage.getItem('ValorCompra'),
    "quantity": 1

  });




  const initialization = {    
    amount: localStorage.getItem('ValorCompra'),
    first_name: localStorage.getItem('Nome')   
  };
  const customization = {
   paymentMethods: {
      ticket: "all",
      bankTransfer: "all",
      creditCard: "all",
      //debitCard: "all",
      mercadoPago: "all",
    },
  // backUrls: {
  //    error: 'https://www.inbravisa.com.br/erropagamento',
  //    return: 'https://www.inbravisa.com.br/AreaAluno',
  //  },
  };

  

  const onSubmit = async (
    { formData }
  ) => {
 
    // callback chamado ao clicar no botão de submissão dos dados
    return new Promise((resolve, reject) => {
      fetch("https://api.mercadopago.com/v1/payments", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Produção Authorization: 'Bearer APP_USR-5802510402411907-011911-04bf819fdd7d6efab7a43eeb8488223a-306070152'
          //Teste Authorization: 'Bearer TEST-5802510402411907-011911-c555c5855e4cb1bff271fbe52539d47a-306070152'
          Authorization: 'Bearer APP_USR-5802510402411907-011911-04bf819fdd7d6efab7a43eeb8488223a-306070152'
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((response) => {
          // receber o resultado do pagamento
          resolve();
            console.log(response)
            console.log('o id da compra é: ' + response.id)
          setCompra(response.id)
            console.log('Resultado do id da compra - ' + compra)
          setStatusCompra(response.status)
            console.log("O status da compra é: " + response.status)

            localStorage.removeItem('idCompraMP')
            localStorage.removeItem('StatusCompraMP')

            localStorage.setItem('StatusCompraMP', response.status )
            localStorage.setItem('idCompraMP', response.id )

            navigate('/Retorno_Pagamento/' + response.status)


        })
        .catch((error) => {
          // lidar com a resposta de erro ao tentar criar o pagamento
          reject();
        });
    });
  };
  const onError = async (error) => {
    // callback chamado para todos os casos de erro do Brick
    console.log(error);
  };
  const onReady = async () => {

    setLoading(false)
    /*
      Callback chamado quando o Brick estiver pronto.
      Aqui você pode ocultar loadings do seu site, por exemplo.
    */
  };

  


  console.log(produto)

  /*
    fetch('/create_preference', produto)
      .then((res) => {
        console.log(res);
  
      }).catch((error) => {
        if (error.response) {
          console.log(error.response.data.mensagem);
  
        } else {
          //console.log('Erro: Tente Novamente por favor! ');
          setStatus({
            type: 'Error',
            mensagem: 'Erro: Tente Novamente por favor! '
          });
  
        }
      })
  */
  function carregando() {
    console.log(loading)
  }

  useEffect(() => {
    onReady();

  }, setLoading)

  return (
    <div className="bg-slate-500">
      <button className="p-5 rounded-md justify-center hover:bg-emerald-800 bg-emerald-600 text-white" onClick={abrirModal}> Realizar Pagamento</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={fecharModal}
        contentLabel="Modal de exemplo"

      >
        <div className="flex justify-end ">
          <button className="bg-gray-400 p-1 text-lg text-white hover:bg-gray-800 flex-col pr-2 pl-2 mb-5 rounded-lg" onClick={fecharModal}>X</button>
        </div>
        <div className="flex justify-center mb-5">
          <p className="text-2xl"> Pagamento  </p>
        </div>
        <p className="flex justify-center mb-5 "><FaLock className="text-emerald-500 text-xl" /> </p>
        <p className="flex justify-center mb-5 ">ambiente é seguro, com certificação SSL para proteção dos seus dados  </p>
        <p className="flex justify-center mb-5">A transação financeira é feita pelo Mercado Pago, as informações são criptografadas e nada fica armazenado no site.</p>


        <hr />
        <div className="pt-2 flex-row w-full h-96">
          <div className="flex justify-center w-full">
            {loading == true ?
              <div>
                <p className="animate-spin text-5xl text-sky-600"><FaSpinner /></p>
                <p></p>
              </div>
              :
              <Payment
                initialization={initialization}
                customization={customization}
                onSubmit={onSubmit}
                onReady={onReady}
                onError={onError}
              />

            }
          </div>





        </div>


      </Modal>

    </div>
  )

}