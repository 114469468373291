import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from "../../config/configAPI";
import CreditCardInput from '../CreditCardInput'; // Supondo que você tenha um componente de entrada de cartão de crédito
import ValidadeCartao from '../ValidadeCartao'; // Supondo que você tenha um componente de validade de cartão de crédito

const PagamentoEnvioCartao = ({ valorTotal, iduser, vencimento }) => {
  const navigate = useNavigate();

  // Número total de parcelas
  const totalParcelas = 10;
  const options = [];
  for (let i = 1; i <= totalParcelas; i++) {
    const parcelamento = parseFloat(valorTotal / i).toFixed(2);
    const valorParceladoFormatado = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(parcelamento);
    options.push(<option key={i} value={i}>{i}x {valorParceladoFormatado}</option>);
  }

  const [formData, setFormData] = useState({
    fullName: '',
    cpf: '',
    cvv: '',
    numeroCartao: '',
    validadeMes: '',
    validadeAno: '',
    installmentCount: '',
    billingType: 'CREDIT_CARD',
    customer: iduser,
    dueDate: vencimento,
    totalValue: valorTotal
  });


  const [valorParcelado, setValorParcelado] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'parcelamento') {
      calcularParcelamento(valorTotal, value);
    }
  };

  const calcularParcelamento = (valor, numParcelas) => {
    if (valor && numParcelas > 0) {
      const valorParcelado = parseFloat(valor / numParcelas).toFixed(2);
      const valorParceladoFormatado = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(valorParcelado);
      setValorParcelado(valorParceladoFormatado);
    } else {
      setValorParcelado('');
    }
  };

  const headers = {
    'headers': {
      'Content-Type': 'application/json'
    }
  };

  const [ip, setIp] = useState('');


  const [dadosUser, setDadosUser] = useState({
    Cep: '',
    Endereco: '',
    Numero: '',
    Complemento:'',
    Whatsapp: '',
    Email: ''

  });

  useEffect(() => {
    const buscusuario = async () => {
      try {
        const locuser = await api.get('/buscaUser/' + localStorage.getItem('idUser'))
        console.log(locuser.data.usuario.Cpf)
        setDadosUser({
          Cep: locuser.data.usuario.Cep,
          Endereco: locuser.data.usuario.Endereco,
          Numero: locuser.data.usuario.Numero,
          Complemento: locuser.data.usuario.Complemento,
          Whatsapp: locuser.data.usuario.Whatsapp,
          Email: locuser.data.usuario.Email
        })
      } catch (error) {
        console.log('não localizou usuário')

      }
    };

    buscusuario();


  }, [])

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await api.get('/get-ip');
        setIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP:', error);
      }
    };

    fetchIp();
  }, []);


  console.log('O IP do usuário é: ' + ip)

  //Cadastrar Compra Asaas
  const handleSubmit = async (e) => {


    e.preventDefault();
    console.log(formData)

    // Enviar dados para o backend
    try {
      const res = await api.post('/pagamentoCartao', formData, headers);
      console.log(res);
      console.log('Id da cobrança é: ' + res.data.id)
      localStorage.setItem('IdCobranca', res.data.id);
      localStorage.setItem('ValorCobrado', res.data.value);
      localStorage.setItem('TipoCobranca', res.data.billingType);

      await ProcessPagamento();
      //await recuperarCompra();
      //await cadPagamento();

      navigate('/PagamentoCartao/');
      // Faça algo com a resposta do backend, como mostrar uma mensagem de sucesso
    } catch (error) {
      console.error('Erro ao processar o pagamento:', error);
      // Lidar com o erro, como mostrar uma mensagem de erro
    }

  };


  const recuperarCompra = async () => {
    try {

      const recCompra = await api.get(`/StatusCompra/id=${localStorage.getItem('IdCobranca')}`)
      console.log('O Status da compra é: ' + recCompra.status)

    } catch (error) {

    }
  }

  
  // Adiciona um dia à data atual
  const dataAtual = new Date();
  const umDiaDepois = new Date(dataAtual);
  umDiaDepois.setDate(umDiaDepois.getDate() + 5);

  //Processamento de Pagamento

  const ProcessPagamento = async () => {
    try {
      const response = await api.post(`/ProcessCartao/id=${localStorage.getItem('IdCobranca')}`, {
          "creditCard": {
          "holderName": formData.fullName,
          "number": formData.numeroCartao,
          "expiryMonth": formData.validadeMes,
          "expiryYear": formData.validadeAno,
          "ccv": formData.cvv
        },
        "creditCardHolderInfo": {
          "name": formData.fullName,
          "email": "olivia@inbravisa.com.br",
          "cpfCnpj": formData.cpf,
          "postalCode": dadosUser.Cep,
          "addressNumber": dadosUser.Numero,
          "addressComplement": dadosUser.Complemento,
          "phone": dadosUser.Whatsapp,
          "mobilePhone": dadosUser.Whatsapp
        },
        "customer": localStorage.getItem('Id Asaas'),
        "dueDate": umDiaDepois,
        "value": valorTotal,        
        "description": localStorage.getItem('NomeAula'),
      }, headers)

      console.log(response)

    } catch (error) {
      console.log('Erro ao processar o pagamento')

    }

  }

  //Cadastrar compra Banco de Dados
  const cadPagamento = async () => {
    const dadoscompra = {
      idCadastro: localStorage.getItem('idUser'),
      idcompra: localStorage.getItem('IdCobranca'),
      idAsaas: localStorage.getItem('Id Asaas'),
      Nome: localStorage.getItem('Nome'),
      idProtocolo: localStorage.getItem('idaula'),
      NomeProtocolo: localStorage.getItem('NomeAula'),
      ImagemProtocolo: localStorage.getItem('Img'),
      idCupom: localStorage.getItem('idCupom'),
      Nomecupom: localStorage.getItem('Cupom'),
      Status: localStorage.getItem('StatusCobranca'),
      Valor: localStorage.getItem('ValorCobrado'),
      Tipo: localStorage.getItem('TipoCobranca')
    };

    try {
      const response = await api.post('/CadComprar', dadoscompra, headers);
      console.log("Cadastro de compra realizado: ", response.data.mensagem);
    } catch (err) {
      console.error("Erro ao cadastrar compra: ", err);
    }
  };



  return (
    <div className="mt-4 w-full">
      <h3 className="text-lg font-bold mb-4">Informações do Cartão de Crédito</h3>
      <form onSubmit={handleSubmit} className="w-full">
        <div className="w-full max-w-xs mb-4">
          <label htmlFor="installmentCount" className="block text-sm font-medium text-gray-700">
            Parcelamento:
          </label>
          <select id="installmentCount" onChange={handleChange} name="installmentCount" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            {options}
          </select>
        </div>

        <div className="w-full mb-4">
          <label htmlFor="NomeCompleto" className="block text-sm font-medium text-gray-700">
            Nome como está no cartão:
          </label>
          <input
            id="NomeCompleto"
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Nome"
            className="border rounded-lg py-2 px-3 w-full outline-none focus:outline-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <div className="w-full mb-4">
          <label htmlFor="CPF" className="block text-sm font-medium text-gray-700">
            CPF do titular:
          </label>
          <input
            id="CPF"
            type="tel"
            name="cpf"
            value={formData.cpf}
            maxLength={11}
            onChange={handleChange}
            placeholder="CPF do Titular"
            className="border rounded-lg py-2 px-3 w-full outline-none focus:outline-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>


        <div className="w-full mb-4">
          <label htmlFor="ncartao" className="block text-sm font-medium text-gray-700">
            Número do Cartão
          </label>
          <input
            id="ncartao"
            type="tel"
            name="numeroCartao"
            value={formData.numeroCartao}
            onChange={handleChange}
            placeholder="numero do cartão"
            className="border rounded-lg py-2 px-3 w-full outline-none focus:outline-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <div className="flex gap-2 mb-4">
          <div className="flex-1">
            <label htmlFor="mesvenc" className="block text-sm font-medium text-gray-700">
              Mês de vencimento
            </label>
            <input
              id="mesvenc"
              type="tel"
              name="validadeMes"
              value={formData.validadeMes}
              maxLength={2}
              onChange={handleChange}
              placeholder="06"
              className="border rounded-lg py-2 px-3 w-full outline-none focus:outline-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="flex-1">
            <label htmlFor="anoVenc" className="block text-sm font-medium text-gray-700">
              Ano de vencimento
            </label>
            <input
              id="anoVenc"
              type="tel"
              name="validadeAno"
              value={formData.validadeAno}
              maxLength={4}
              onChange={handleChange}
              placeholder="2024"
              className="border rounded-lg py-2 px-3 w-full outline-none focus:outline-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="flex-1">
            <label htmlFor="codigovv" className="block text-sm font-medium text-gray-700">
              Código de Segurança
            </label>
            <input
              id="codigovv"
              type="tel"
              name="cvv"
              value={formData.cvv}
              onChange={handleChange}
              placeholder="123"
              className="border rounded-lg py-2 px-3 w-full outline-none focus:outline-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>

        <button type="submit" className="mt-4 w-full py-2 px-4 text-bold bg-green-700 hover:bg-green-900 text-white font-bold items-center rounded-lg">
          Processar Pagamento
        </button>
      </form>
    </div>
  );
};

export default PagamentoEnvioCartao;