import React from "react";
import Servicosaude from '../../Assets/servicosaude.jpg';
import ImportadoraDistribuidora from '../../Assets/importadora.jpg';
import Fabricantes from '../../Assets/fabricante.jpg';
import Aperto from '../../Assets/apertomaos.jpg';
import { Link } from "react-router-dom";
import { RiShareForwardBoxLine } from "react-icons/ri";

export const Servicos = () => {
    return (
        <>

            <div className="flex flex-col xl:flex-row gap-6 justify-center items-center">
                {/* Conte Conosco */}
                <Link to={'/institucional/servicos'}>
                    <div className="relative bg-white shadow-md rounded-lg overflow-hidden w-full max-w-xs text-center hover:shadow-xl transition-shadow duration-300 min-h-[400px] hover:scale-105">
                        <div
                            className="absolute inset-0 bg-cover bg-center opacity-40"
                            style={{ backgroundImage: `url(${Aperto})` }}
                        ></div>
                        <div className="relative p-6">
                            <h2 className="text-xl font-bold mb-4 text-gray-300  bg-slate-900 bg-opacity-70 pl-2 pr-2 ">Consultoria</h2>
                            <p className="text-gray-300 bg-slate-900 bg-opacity-70">
                                Oferecemos soluções e serviços especializados para serviços e produtos sob regime da ANVISA.
                            </p>
                            <br />
                            <p className="text-gray-300 bg-slate-900 bg-opacity-70">
                                Conheça nossas cartas de serviços
                            </p>
                            <p className="text-gray-300 bg-slate-900 bg-opacity-70">
                                Conte com a gente quando o assunto for Vigilância Sanitária
                            </p>
                        </div>
                        {/* Adicionar o botão na parte inferior do card */}

                        <div className="absolute left-0 right-0 bottom-0 p-4">
                            <Link to={'/institucional/servicos'} className="flex items-center justify-center w-full bg-blue-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-300">
                                <span className="mr-4">Saiba mais</span>
                                <RiShareForwardBoxLine />
                            </Link>

                        </div>
                    </div>
                </Link>

                {/* Card 1 - Serviço de Saúde */}
                <Link to={'/institucional/Mentoria'}>
                    <div className="relative bg-white shadow-md rounded-lg overflow-hidden w-full max-w-xs text-center hover:shadow-xl transition-shadow duration-300 min-h-[400px] hover:scale-105">
                        <div
                            className="absolute inset-0 bg-cover bg-center opacity-40"
                            style={{ backgroundImage: `url(${Servicosaude})` }}
                        ></div>
                        <div className="relative p-6">
                            <h2 className="text-xl font-bold mb-4 text-gray-300  bg-slate-900 bg-opacity-70 pl-2 pr-2 ">Mentorias</h2>
                            <p className="text-gray-300 bg-slate-900 bg-opacity-70">
                                Realizamos Mentorias para direcionar e adequar seus serviços frente às exigências da ANVISA

                            </p>
                        </div>
                        {/* Adicionar o botão na parte inferior do card */}
                        <div className="absolute left-0 right-0 bottom-0 p-4">

                            <Link to={'/institucional/Mentoria'} className="flex items-center justify-center w-full bg-blue-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-300">
                                <span className="mr-4">Saiba mais</span>
                                <RiShareForwardBoxLine />
                            </Link>
                        </div>
                    </div>
                </Link>

                {/* Card 2 - Importadora/Distribuidora */}
                <Link to={'/institucional/Treinamentos'}>
                    <div className="relative bg-white shadow-md rounded-lg overflow-hidden w-full max-w-xs text-center hover:shadow-xl transition-shadow duration-300 min-h-[400px] hover:scale-105">
                        <div
                            className="absolute inset-0 bg-cover bg-center opacity-40"
                            style={{ backgroundImage: `url(${ImportadoraDistribuidora})` }}
                        ></div>
                        <div className="relative p-6">
                            <h2 className="text-xl font-bold mb-4 text-gray-300 bg-slate-900 bg-opacity-70 pl-2 pr-2">Treinamento de Equipes</h2>
                            <p className="text-gray-300 bg-slate-900 bg-opacity-70">
                                Treinamos e capacitamos os profissionais da sua atividade para atenderem as normas da ANVISA
                            </p>
                        </div>

                        {/* Adicionar o botão na parte inferior do card */}
                        <div className="absolute left-0 right-0 bottom-0 p-4">
                            <button className="flex items-center justify-center w-full bg-blue-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-300">
                                <span className="mr-4">Saiba mais</span>
                                <RiShareForwardBoxLine />
                            </button>
                        </div>
                    </div>
                </Link>

                <Link to={'/Cursos'}>
                    {/* Card 3 - Fabricantes */}
                    <div className="relative bg-white shadow-md rounded-lg overflow-hidden w-full max-w-xs text-center hover:shadow-xl transition-shadow duration-300 min-h-[400px] hover:scale-105">
                        <div
                            className="absolute inset-0 bg-cover bg-center opacity-40"
                            style={{ backgroundImage: `url(${Fabricantes})` }}
                        ></div>
                        <div className="relative p-6">
                            <h2 className="text-xl font-bold mb-4 text-gray-300  bg-slate-900 bg-opacity-70 ">Cursos Online</h2>
                            <p className="text-gray-300 bg-slate-900 bg-opacity-70">
                                Realizamos Cursos online para as mais diversas áreas afim de proporcionar um maior conhecimento reglatório da sua atividade
                            </p>
                        </div>
                        {/* Adicionar o botão na parte inferior do card */}
                        <div className="absolute left-0 right-0 bottom-0 p-4">
                            <button className="flex items-center justify-center w-full bg-blue-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-300">
                                <span className="mr-4">Saiba mais</span>
                                <RiShareForwardBoxLine />
                            </button>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
};