import React, { useState } from 'react';
import { Card } from '../../Components/Card';
import { Menu } from '../../Components/Menu';
import { Rodape } from "../../Components/Rodape";
import { WhatsappBtn } from '../../Components/Whatsapp';
import { CookieConsent } from '../../Components/CookieConsent';


function sair() {

    //console.log("Saiu")
    //localStorage.removeItem("idUser");
    //localStorage.removeItem("token");
    //localStorage.removeItem("Nome");



}



export const Cursos = () => {
    localStorage.removeItem('ValorCobrado');
    localStorage.removeItem('ValorCompra');
    localStorage.removeItem('StatusCobranca');
    localStorage.removeItem('TipoCobranca');
    localStorage.removeItem('IdCobranca');
    localStorage.removeItem('Img');
    localStorage.removeItem('idaula');
    localStorage.removeItem('NomeAula');


    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <>

            <div>
                <div className='mb-20'>
                    <Menu />
                    <br />
                </div>                
                
            <div className="flex flex-wrap justify-center mb-64">
                <Card />

            </div>

            
                <div>
                    <CookieConsent />
                </div>
                <div className="relative">
                    {/* Outros componentes e conteúdo da página */}
                    <WhatsappBtn />
                </div>

            </div>
            <div class=" mt-10">
                <Rodape />

            </div>
        </>
    )
}