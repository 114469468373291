import React, { useContext } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import { Context } from '../Context/authContext';


import { Home } from '../Pages/Home';
import { Protocolo } from '../Pages/Protocolo';
import { Comprar } from "../Pages/Comprar";
import { Login } from "../Pages/Login";
import { Area_Aluno } from "../Pages/Area_Aluno";
import { Assistir } from "../Pages/Assistir";
import { Listar_Aulas } from "../Pages/Listar_Aulas";
import { Cadastro_Usuario } from '../Pages/Cadastro_Usuario';
import { Checkout } from '../Pages/Checkout';
import { Retorno_Pagamento } from '../Pages/Retorno_Pagamento';
import { RecSenha } from '../Pages/Rec_senha';
import { Servicos } from '../Pages/Institucional/servicos';
import { Quemsomos } from '../Pages/Institucional/quemsomos';
import { SobreEmpresa } from '../Pages/Institucional/SobreEmpresa';
import { Contato } from '../Pages/Institucional/contato';
import { Certificacao } from '../Pages/Institucional/certificacao';
import { Layout } from '../Pages/layouttst';
import { Pagamento } from '../Pages/Pagamento';
import { PagamentoBoleto } from '../Pages/PagamentoBoleto';
import { PagamentoCartao } from '../Pages/PagamentoCartao';
import { Certificado } from '../Pages/Certificado';

import {Cursos} from '../Pages/Cursos'

import {Mentoria} from '../Pages/Institucional/Mentoria'
import {Treinamentos} from '../Pages/Institucional/Treinamentos'

function CustomRoute({ children, redirectTo }) {

    const { authenticated } = useContext(Context)

    return authenticated ? children : <Navigate to={redirectTo} />

}

export default function RoutesAdm() {
    return (
        <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/Protocolo/:id" element={<Protocolo />} />

            <Route path="/Certificado/" element={<Certificado />} />


            <Route path="/Login/:produto" element={<Login />} />
            <Route path="/Login/" element={<Login />} />
            <Route path="/Rec_senha/" element={<RecSenha />} />
            <Route path="/Cadastro_Usuario" element={<Cadastro_Usuario />} />
            <Route path="/Retorno_Pagamento/:statuscompra" element={<Retorno_Pagamento />} />
            <Route path="/layouttst/:id" element={<Layout />} />

            //Acesso Institucional
            <Route path="/institucional/empresa" element={<SobreEmpresa />} />
            <Route path="/institucional/servicos" element={<Servicos />} />
            <Route path="/institucional/quemsomos" element={<Quemsomos />} />
            <Route path="/institucional/certificacao" element={<Certificacao />} />
            <Route path="/institucional/contato" element={<Contato />} />
            <Route path="/institucional/Mentoria" element={<Mentoria />} />
            <Route path="/institucional/Treinamentos" element={<Treinamentos />} />
            <Route path="/Cursos" element={<Cursos />} />




            //Acesso Restrito
            <Route path="/Comprar/:idProduto" element={<CustomRoute redirectTo="/Login"><Comprar /></CustomRoute>} />
            <Route path="/Pagamento" element={<CustomRoute redirectTo="/Login"><Pagamento /></CustomRoute>} />
            <Route path="/PagamentoBoleto" element={<CustomRoute redirectTo="/Login"><PagamentoBoleto /></CustomRoute>} />
            <Route path="/PagamentoCartao" element={<CustomRoute redirectTo="/Login"><PagamentoCartao /></CustomRoute>} />


            <Route path="/Checkout/:idProduto" element={<CustomRoute redirectTo="/Login"><Checkout /></CustomRoute>} />
            <Route path="/Area_Aluno" element={<CustomRoute redirectTo="/Login"><Area_Aluno /> </CustomRoute>} />
            <Route path="/Listar_Aulas/:idProtocolo" element={<CustomRoute redirectTo="/Login"><Listar_Aulas /> </CustomRoute>} />
            <Route path='/Assistir/:idaula/:NomeAula/:idProtocolo' element={<CustomRoute redirectTo="/Login"> <Assistir /> </CustomRoute>} />

        </Routes>
    );
}