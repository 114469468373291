import React, { useState, useEffect } from "react";
import api from "../../config/configAPI";
import { Rodape } from "../../Components/Rodape";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { Menu } from "../../Components/Menu"
import { Menu as MenuAluno } from "../../Components/Menu_Aluno"


import { FaBarcode } from "react-icons/fa6";
import { CopiarBoleto } from "../../Components/CopiarBoleto";

export const PagamentoBoleto = () => {


    const navigate = useNavigate();

    const token = localStorage.getItem('token');
    const cobranca = localStorage.getItem('IdCobranca');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [nboleto, setNboleto] = useState({
        identificationField: '',
        nossoNumero: '',
        barCode: ''
    });

    const headers = {
        'Content-Type': 'application/json'
    };

    const Boleto = async () => {         
            await api.get(`/BoletoCdg?id=${localStorage.getItem('IdCobranca')}`,  headers)
            .then((response) =>{
              console.log(response)
             console.log('Acessou a API')
             setNboleto({
                identificationField: response.data.identificationField,
                 nossoNumero: response.data.nossoNumero,
                 barCode: response.data.barCode 
            
             
             });
             
             //console.log('codigo de barras' + response.data.identificationField)
             setIsLoading(false);
             cadPagamento();
            })
    }

    useEffect(() => {
        Boleto();
        setIsLoading(false);
    }, []);

    const cadPagamento = async () => {
        const dadoscompra = {
            idCadastro: localStorage.getItem('idUser'),
            idcompra: localStorage.getItem('IdCobranca'),
            idAsaas: localStorage.getItem('Id Asaas'),
            Nome: localStorage.getItem('Nome'),
            idProtocolo: localStorage.getItem('idaula'),
            NomeProtocolo: localStorage.getItem('NomeAula'),
            ImagemProtocolo: localStorage.getItem('Img'),
            idCupom: localStorage.getItem('idCupom'),
            Nomecupom: localStorage.getItem('Cupom'),
            Status: '',
            Valor: localStorage.getItem('ValorCobrado'),
            Tipo: localStorage.getItem('TipoCobranca')
        };

        try {
            const response = await api.post('/CadComprar', dadoscompra, headers);
            console.log("Cadastro de compra realizado: ", response.data.mensagem);
        } catch (err) {
            console.error("Erro ao cadastrar compra: ", err);
        }
    };
    

    if (!token) {
        return <Navigate to="/login" />;
    }

    if (isLoading) {
        return (
            <div>
                <p> Carregando...</p>
                <a target='_blank' href={localStorage.getItem('urlBoleto')}> Abrir Boleto</a>
                <p>pixData - {nboleto.encodedImage}</p>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    const formatCurrency = (value) => {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };

    
    const Finalizar = () => {
        
        navigate('/Area_Aluno');
    }



    return (
        <div>
            <div className="container mx-auto mt-8">
                <div className="mt-28">
                    {token ? <Menu /> : <MenuAluno />}
                </div>

                <div className="border border-blue-400 p-2">
                    <div className="w-full md:flex md:space-x-8 md:p-0">
                        <div className="w-full md:w-1/2 md:border-r border-blue-400 md:p-5">
                            <div>
                                <h2 className="text-lg font-bold mb-4 flex items-center">
                                    Clique no botão para abrir o boleto <FaBarcode className="ml-2" />
                                </h2>
                                <span>O pagamento por boleto bancário pode levar até 3 dias úteis para a compensação</span>
                            </div>
                            <div className="border p-4 mb-8">
                                <div className="flex flex-row items-center justify-center">
                                    <a className="bg-sky-500 w-full p-5 text-white text-center justify-center rounded-2xl text-lg font-bold mb-4 flex items-center" target='_blank' href={localStorage.getItem('urlBoleto')}> Abrir Boleto <FaBarcode className="ml-2" /></a>


                                </div>
                                <div className="mt-8">

                                </div>
                                <div className="bg-slate-300 w-full items-center rounded-md p-5">
                                    <p className="flex flex-row justify-center text-xl text-bold">Valor: {formatCurrency(parseFloat(localStorage.getItem('ValorCobrado')))}</p>
                                </div>
                                <br />
                            </div>
                        </div>                       
                       
                                    <CopiarBoleto texto={nboleto.barCode} />                               


                    </div>

                    

                    <div className="flex ">
                        <button className="bg-emerald-500 text-white w-full p-2 m-2 rounded-md" onClickCapture={Finalizar}>
                            Finalizar
                        </button>
                    </div>

                </div>

                <br /><br /><br /><br /><br /><br /><br />
            </div>
            <div className="relative mt-10">
                <Rodape />
            </div>
        </div>
    );
};