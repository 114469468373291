import React, { useState, useEffect } from "react";
import api from "../../config/configAPI";
import { Rodape } from "../../Components/Rodape";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { Menu } from "../../Components/Menu";
import { Menu as MenuAluno } from "../../Components/Menu_Aluno";


//componentes
import { CopiarTexto } from "../../Components/CopiarTexto";

export const Pagamento = () => {
    const navigate = useNavigate();

    const token = localStorage.getItem('token');
    const cobranca = localStorage.getItem('IdCobranca');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pixData, setPixData] = useState({
        encodedImage: '',
        payload: '',
        expirationDate: ''
    });

    const headers = {
        'Content-Type': 'application/json'
    };

    const PagamentoPix = async () => {
        try {
            const response = await api.get(`/gerarPix?id=${cobranca}`, { headers });
            setPixData({
                encodedImage: response.data.encodedImage,
                payload: response.data.payload,
                expirationDate: response.data.expirationDate
            });
            setIsLoading(false);
        } catch (error) {
            console.error("Erro ao gerar Pix:", error);
            setError('Erro ao gerar Pix. Tente novamente mais tarde.');
            setIsLoading(false);
        }
    };

    useEffect(() => {
        PagamentoPix();
    }, []);

    if (!token) {
        return <Navigate to="/login" />;
    }

    if (isLoading) {
        return <div>Carregando...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const formatCurrency = (value) => {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };

const Finalizar = () =>{
    navigate ('/Area_Aluno');
}



    return (
        <div>
            <div className="container mx-auto mt-8">
                <div className="mt-28">
                    {token ? <Menu /> : <MenuAluno />}
                </div>

                <div className="border border-blue-400 p-2">
                    <div className="w-full md:flex md:space-x-8 md:p-0">
                        <div className="w-full md:w-1/2 md:border-r border-blue-400 md:p-5">
                            <h2 className="text-lg font-bold mb-4">Scaneie o QR Code para efetuar o pagamento</h2>
                            <div className="border p-4 mb-8">
                                <div className="flex flex-row items-center justify-center">
                                    <img
                                        src={`data:image/png;base64,${pixData.encodedImage}`}
                                        alt="QRCode Pix"
                                        className="mb-4 w-64 h-64 object-contain"
                                    />

                                </div>
                                <div className="mt-8"></div>
                                <div className="bg-slate-300 w-full items-center rounded-md p-5">
                                    <p className="flex flex-row justify-center text-xl text-bold">{formatCurrency(parseFloat(localStorage.getItem('ValorCobrado')))}</p>
                                </div>
                                <br />
                            </div>
                        </div>

                        <CopiarTexto texto={pixData.payload} />
                    </div>
                    <div className="flex ">
                        <button className="bg-emerald-500 text-white w-full p-2 m-2 rounded-md" onClickCapture={Finalizar}>
                            Finalizar
                        </button>
                    </div>

                </div>

                <br /><br /><br /><br /><br /><br /><br />
            </div>
            <div className="relative mt-10">
                <Rodape />
            </div>
        </div>
    );
};